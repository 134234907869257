<template>
  <div class="terms">
    <!-- Terms of Service <<<< start -->
    <section class="service container">
      <div class="service_in row">
        <div class="service_text col-12 col-md-6 d-flex flex-column justify-content-center mb-5 mb-md-0">
          <h1 class="main_header service_header text-center text-md-start">Terms of Service</h1>
          <div class="service_text_w">
            <p>
              Please read these terms of use carefully. By accessing or using
              the services, you agree to be legally bound by these terms of use
              and all terms incorporated by reference.
            </p>
          </div>
        </div>

        <div class="service_i_w col-12 col-md-6 text-center text-md-end">
          <img
            src="../assets/images/termsofservice.png"
            alt="Bitcoin"
            class="service_i mw-100"
          />
        </div>
      </div>
    </section>
    <!-- Terms of Service >>>> end -->

    <!-- Terms of Service Content <<<< start -->
    <section class="terms_desc container">
      <div class="terms_block">
        <h2 class="title">Summary of Terms of Service</h2>
        <div class="terms_text_w">
          <p>
            This summary of our Terms of Service offers you an overview of the
            key terms that apply to your use of our website and trading
            services. While we hope this summary section is helpful, you should
            read the complete Terms of Service below since they provide
            important information about how our services work. Please note that
            we refer to our online service where you can execute trades as
            “Profitrade” (<a class="terms_desc_links" href="http://profitrade.net"
              >profitrade.net</a
            >,
            <a class="terms_desc_links" href="http://profitrade.ng">profitrade.ng</a>).
          </p>
        </div>
      </div>
      <div class="terms_block">
        <h2 class="title">Our Services</h2>
        <div class="terms_text_w">
          <p>
            Eland Trade Solutions Ltd (trading as Profitrade) provides you with
            a simple and convenient way to trade one type of digital asset for
            another type of digital asset. Profitrade provides you with a
            platform that matches your trades with open orders from other users
            of our services at your direction. Users are not able to
            predetermine a trade with a particular user or with a particular
            account. Additionally, an order may be partially filled or may be
            filled by multiple matching orders.
          </p>
        </div>
      </div>
      <div class="terms_block">
        <h2 class="title">Eligibility and Acceptable Use</h2>
        <div class="terms_text_w">
          <p>
            You must meet certain eligibility criteria to use Profitrade. For
            instance, you must be at least 18 years of age and there are certain
            locations from which you may not be able to use some or all of
            Profitrade. Additionally, there are certain things you cannot do
            when using Profitrade, such as engage in illegal activities, lie, or
            do anything that would cause damage to our services or systems.
            Please see the acceptable use section for more details.
          </p>
        </div>
      </div>
      <div class="terms_block">
        <h2 class="title">Trading Risks</h2>
        <div class="terms_text_w">
          <p>
            Engaging in trades may be risky, especially if you use sophisticated
            trading options. Please do not use Profitrade or any of the trading
            options if you do not understand these risks. Crypto-currencies (aka
            Digital Assets) is a new and unknown financial area and trading in
            crypto currencies you may be exposed to market, volatility, legal
            and other risks. You should not use the money that you can't afford
            to lose to trade in crypto currencies.
          </p>
        </div>
      </div>
      <div class="terms_block">
        <h2 class="title">Other Important Legal Terms</h2>
        <div class="terms_text_w">
          <p>
            There are important legal terms provided below in the complete Terms
            of Use, including your indemnification responsibilities, our
            limitation of liability and warranty disclaimers, and your agreement
            to arbitrate most disputes. Please take the time to read these terms
            carefully. You can always contact us through support if you have any
            questions
            <a class="terms_desc_links" href="mailto:support@profitrade.net"
              >support@profitrade.net</a
            >
          </p>
        </div>
      </div>
      <div class="terms_block">
        <h2 class="title">Complete Terms of Use</h2>
        <div class="terms_text_w">
          <p>
            These Terms of Use and any terms expressly incorporated herein
            (“Terms”) apply to your use of the website operated and maintained
            by Eland Trade Solutions Ltd and its wholly owned subsidiaries
            (collectively, “Profitrade”, “we”, or “us”), and the trading
            services provided by Profitrade as described in these Terms
            (collectively, our “Services”).
          </p>
        </div>
      </div>

      <div class="terms_block">
        <h3 class="title_num">1. Key Definitions</h3>
        <div class="terms_text_w">
          <p>
            Capitalized terms not otherwise defined in these Terms will have the
            following meaning:
          </p>
          <p>
            1.1. “External Account” means any Digital Asset Account: (i) from
            which you may load Funds into your Profitrade Account, and (ii) to
            which you may push Funds from your Profitrade Account.
          </p>
          <p>
            1.2. “Financial Account” means any financial account of which you
            are the beneficial owner that is maintained by a third party outside
            of the Services, including, but not limited to third-party payment
            service accounts or accounts maintained by third party financial
            institutions.
          </p>
          <p>1.3. “Funds” means Digital Asset.</p>
          <p>
            1.4. “Profitrade Account” means a user account accessible via the
            Services where Funds may be stored by Profitrade on behalf of a
            user.
          </p>
          <p>
            1.5. “Digital Asset” means Bitcoins, Ethereum and other digital
            mediums of exchange that may be purchased, sold or traded via the
            Services.
          </p>
          <p>
            1.6. “Digital Asset Account” means any Digital Asset address or
            account owned or operated by you that is maintained outside of the
            Services, and is not owned, controlled or operated by Profitrade.
          </p>
        </div>
      </div>

      <div class="terms_block">
        <h3 class="title_num">2. Eligibility</h3>
        <div class="terms_text_w">
          <p>
            2.1. Profitrade may not make the Services available in all markets
            and jurisdictions, and may restrict or prohibit use of the Services
            from certain jurisdictions.
          </p>
          <p>
            Such jurisdictions include USA and countries on the FATF list of
            high risk countries such as Cuba, North Korea, Somalia, Syria, Iran,
            Iraq and others. Customers from these countries are not permitted to
            use Profitrade services.
          </p>
          <p>
            2.2. You further represent and warrant that you: (a) are at least 18
            years old; (b) have not previously been suspended or removed from
            using our Services; (c) have full power and authority to enter into
            this agreement and in doing so will not violate any other agreement
            to which you are a party; (d) using our platform in your own name
            and for your own benefit; (e) are not located in, under the
            jurisdiction of, or a national or resident of any Restricted
            Locations; and (f) will not use our Services if any applicable laws
            in your country prohibit you from doing so in accordance with these
            Terms.
          </p>
          <p>
            2.3. Additionally, by agreeing to these terms you certify that you
            pursue participation in blockchain-based networks as a part of your
            professional activity.
          </p>
        </div>
      </div>

      <div class="terms_block">
        <h3 class="title_num">3. Profitrade Account</h3>
        <div class="terms_text_w">
          <p>
            3.1. Number of Profitrade Accounts. Profitrade may, in its sole
            discretion, limit the number of Profitrade Accounts that you may
            hold, maintain or acquire.
          </p>
          <p>
            3.2. Profitrade Account information and security. In order to engage
            in any trades via the Services, you must create a Profitrade Account
            and provide any requested information. When you create a Profitrade
            Account, you agree to: <b>(a)</b> create a strong password that you
            do not use for any other website or online service;
            <b>(b)</b> provide accurate and truthful information;
            <b>(c)</b> maintain and promptly update your Profitrade Account
            information; <b>(d)</b> maintain the security of your Profitrade
            Account by protecting your password and restricting access to your
            Profitrade Account; <b>(e)</b> promptly notify us if you discover or
            otherwise suspect any security breaches related to your Profitrade
            Account; and <b>(f)</b> take responsibility for all activities that
            occur under your Profitrade Account and accept all risks of any
            authorized or unauthorized access to your Profitrade Account, to the
            maximum extent permitted by law.
          </p>
        </div>
      </div>

      <div class="terms_block">
        <h3 class="title_num">4. Privacy Policy</h3>
        <div class="terms_text_w">
          <p>
            Please refer to our Privacy Policy for information about how we
            collect, use and share your information.
          </p>
        </div>
      </div>

      <div class="terms_block">
        <h3 class="title_num">5. General Obligations</h3>
        <div class="terms_text_w">
          <p>
            This Section 5 applies to: <b>(i)</b> all trades completed via the
            Services, <b>(ii)</b> your purchase and/or sale of Digital Assets
            directly from Profitrade via the Services, and <b>(iii)</b> any
            transaction in which you load Funds into your Profitrade Account
            from your External Account or push Funds from your Profitrade
            Account into an External Account.
          </p>
          <p>
            5.1. Conditions and Restrictions. We may, at any time and in our
            sole discretion, refuse any trade submitted via the Services, impose
            limits on the trade amount permitted via the Services or impose any
            other conditions or restrictions upon your use of the Services
            without prior notice. For example, we may limit the number of open
            orders that you may establish via the Services or we may restrict
            trades from certain locations.
          </p>
          <p>
            5.2. Accuracy of Information. You must provide any information
            required when creating a Profitrade Account or when prompted by any
            screen displayed within the Services. You represent and warrant that
            any information you provide via the Services is accurate and
            complete.
          </p>
          <p>
            5.3. Cancellations. You may only cancel an order initiated via the
            Services if such cancellation occurs before Profitrade executes the
            transaction. Once your order has been executed, you may not change,
            withdraw or cancel your authorization for Profitrade to complete
            such transaction. If an order has been partially filled, you may
            cancel the unfilled remainder unless the order relates to a market
            trade. We reserve the right to refuse any cancellation request
            associated with a market order once you have submitted such order.
            In contrast to exchange orders, all trades are irreversible once
            initiated. While we may, at our sole discretion, reverse a trade
            under certain extraordinary conditions, a customer does not have a
            right to a reversal of a trade.
          </p>
          <p>
            5.4. Insufficient Funds. If you have an insufficient amount of Funds
            in your Profitrade Account to complete an order via the Services, we
            may cancel the entire order or may fulfill a partial order using the
            amount of Funds currently available in your Profitrade Account, less
            any fees owed to Profitrade in connection with our execution of the
            trade.
          </p>
          <p>
            5.5. Taxes. It is your responsibility to determine what, if any,
            taxes apply to the trades you complete via the Services, and it is
            your responsibility to report and remit the correct tax to the
            appropriate tax authority. You agree that Profitrade is not
            responsible for determining whether taxes apply to your trades or
            for collecting, reporting, withholding or remitting any taxes
            arising from any trades.
          </p>
        </div>
      </div>

      <div class="terms_block">
        <h3 class="title_num">6. Profitrade Account Funds</h3>
        <div class="terms_text_w">
          <p>
            6.1. Funding your Profitrade Account. In order to complete an order
            or trade via the Services (as described in Section 7), you must
            first load Funds to your Profitrade Account using one of the
            approved and available payment options. You may be required to
            verify that you control the External Account that you use to load
            Funds to your Profitrade Account. You may be charged fees by the
            External Account you use to fund your Profitrade Account. Profitrade
            is not responsible for any External Account fees or for the
            management and security of any External Account. You are solely
            responsible for your use of any External Account, and you agree to
            comply with all terms and conditions applicable to any External
            Account. The timing associated with a load transaction will depend
            in part upon the performance of third parties responsible for
            maintaining the applicable External Account, and Profitrade makes no
            guarantee regarding the amount of time it may take to load Funds
            into your Profitrade Account. If you change your mind and decide to
            request a refund/withdrawal of the funds deposited into your
            account, you may do so, as long as the funds have not been used in
            any orders or open trades. The refund/withdrawal will be processed
            to the same External account at you used to fund your Arkanum
            Technology account.
          </p>
          <p>
            6.2. Pushing Funds to an External Account. Provided that the balance
            of Funds in your Profitrade Account is greater than any minimum
            balance requirements needed to satisfy any of your open positions,
            you may push any amount of Funds, up to the total amount of Funds in
            your Profitrade Account in excess of such minimum balance
            requirements, from your Profitrade Account to an External Account,
            less any fees charged by Profitrade for such transactions (as
            described in the Fee Schedule at the time of your request to push
            Funds to an External Account).
          </p>
          <p>
            6.3. Load/Push Authorization. When you request that we load Funds
            into your Profitrade Account from your External Account or request
            that we push Funds to your External Account from your Profitrade
            Account, you authorize Profitrade to execute such transaction via
            the Services.
          </p>
          <p>
            6.4. Rejected Transactions. In some cases, the External Account may
            reject your Funds or may otherwise be unavailable. You agree that
            you will not hold Profitrade liable for any damages resulting from
            such rejected transactions.
          </p>
        </div>
      </div>

      <div class="terms_block">
        <h3 class="title_num">7. Exchange Orders and Trades</h3>
        <div class="terms_text_w">
          <p>
            This Section applies only when you use the Services to trade Digital
            Assets for another form of Digital Assets. Profitrade does not
            purchase, sell, or exchange any Digital Assets on its own behalf.
          </p>
          <p>
            7.1. Authorization. When you submit a new order via the Services,
            you authorize Profitrade to execute a transaction in accordance with
            such order on a spot basis and charge you any applicable fees (as
            described in Section 9 below).
          </p>
          <p>
            7.2. Independent relationship. You acknowledge and agree that: (a)
            Profitrade is not acting as your broker, intermediary, agent, or
            advisor or in any fiduciary capacity, and (b) no communication or
            information provided to you by Profitrade shall be considered or
            construed as advice.
          </p>
          <p>
            7.3. Trade confirmation. Once the Services execute your trade, a
            confirmation will be electronically made available via the Services
            detailing the particulars of the trade. You acknowledge and agree
            that the failure of the Services to provide such confirmation shall
            not prejudice or invalidate the terms of such trade.
          </p>
          <p>
            7.4. Market rates. If you select a market trade, Profitrade will
            attempt, on a commercially reasonable basis, to execute the trade on
            or close to the prevailing market exchange rate, as defined via the
            Services. You acknowledge and agree that the exchange rate
            information made available via our Services may differ from
            prevailing exchange rates made available via other sources outside
            of the Services.
          </p>
          <p>
            7.5. Market volatility. Particularly during periods of high volume,
            illiquidity, fast movement or volatility in the marketplace for any
            Digital Assets or Legal Tender, the actual market rate at which a
            market order or trade is executed may be different from the
            prevailing rate indicated via the Services at the time of your order
            or trade. You understand that we are not liable for any such price
            fluctuations. In the event of a market disruption or Force Majeure
            event (as defined in Section 22), Profitrade may do one or more of
            the following: <b>(a)</b> suspend access to the Services; or
            <b>(b)</b> prevent you from completing any actions via the Services,
            including closing any open positions. Following any such event, when
            trading resumes, you acknowledge that prevailing market rates may
            differ significantly from the rates available prior to such event.
          </p>
          <p>
            7.6. Trade Settlement. Subject to the terms and conditions in these
            Terms, we will use commercially reasonable efforts to settle trades
            on a spot basis within two (2) days of the date upon which users
            have agreed to execute a trade via the Services.
          </p>
        </div>
      </div>

      <div class="terms_block">
        <h3 class="title_num">8. Risk Disclosure</h3>
        <div class="terms_text_w">
          <p>
            8.1. Trading risks. You acknowledge and agree that you shall access
            and use the Services at your own risk. The risk of loss in trading
            Digital Asset pairs and Digital Asset and Legal Tender pairs can be
            substantial. You should, therefore, carefully consider whether such
            trading is suitable for you in light of your circumstances and
            financial resources. You should be aware of the following points:
          </p>
          <ul class="terms_list">
            <li class="terms_item">
              All Digital Asset positions involve risk, and a “spread” position
              may not be less risky than an outright “long” or “short” position.
            </li>
            <li class="terms_item">
              All of the points noted above apply to all digital asset pair and
              digital asset and legal tender pair trading. This brief statement
              cannot, of course, disclose all the risks and other aspects
              associated with these trades.
            </li>
          </ul>
          <p>
            8.2. Internet transmission risks. You acknowledge that there are
            risks associated with utilizing an Internet-based trading system
            including, but not limited to, the failure of hardware, software,
            and Internet connections. You acknowledge that Profitrade shall not
            be responsible for any communication failures, disruptions, errors,
            distortions or delays you may experience when trading via the
            Services, howsoever caused.
          </p>
        </div>
      </div>

      <div class="terms_block">
        <h3 class="title_num">9. Fees and Payments</h3>
        <div class="terms_text_w">
          <p>
            9.1. Each transaction that you process using our service is subject
            to a transaction fee. We may, at our discretion, deduct transaction
            fees from your accounts either on an individual per transaction
            basis or on a daily-consolidated basis.
          </p>
          <p>
            We will deduct the applicable transaction fees from the digital
            currency or fiat currency balances you hold in your Profitrade
            accounts. By using our Website and our Services, you agree that we
            may use any digital currency and/or fiat currency ("funds") held in
            your Profitrade account to pay any outstanding amount owed by you to
            Profitrade under these Terms of Service (regardless of the digital
            currency or fiat currency to which those amounts relate). If you owe
            us an amount in one currency and do not have sufficient funds in
            that currency then we may deduct the fees in another currency to
            effect payment. Wherever practicable, we will notify you in advance
            before making such a deduction. If there are insufficient funds in
            your Profitrade accounts when we collect our fees, we may restrict
            your account access. You can restore your account access by
            depositing additional funds that at least meet the transaction fees
            owed to Profitrade. By depositing additional funds, you authorise
            Profitrade to deduct any amounts due from your Profitrade accounts
            before restoring your access.
          </p>
          <p>
            9.2. There are currently no fees to receive or deposit digital
            currency into your Profitrade wallet account, or to withdraw fiat
            currency to your bank account. However, network fees will apply when
            making a payment out of your accounts, to transfer, withdraw or pay
            digital currency to external wallet addresses. Profitrade neither
            warrants that its fees will remain unchanged nor that the current
            Fee-Free Services will remain so in the future.
          </p>
          <p class="terms_text_indent">
            9.2.1. There is also no fee when depositing funds via local banking
            services in Nigeria.
          </p>
          <p>
            9.3. You must only fund your account with us using a payment option
            supported by Profitrade and these may change from time to time.
            Deposits made via unsupported methods (including deposits from
            credit cards) may be subject to additional payment processing fees,
            as charged by our service provider. If applicable, such fees would
            be disclosed at the time of the deposit or listed on our website
            with general fees information. You agree we may deduct the
            processing fees from available funds before releasing the deposit to
            your Profitrade account. You must ensure that your deposit
            instructions (including but not limited to Sort Code, SWIFT, IBAN,
            BSB, Account Number, Transaction Reference, Biller Code or Customer
            Reference Number) are treated in confidence and not shared with
            anyone.
          </p>
          <p>
            9.4. Disputing a deposit service transaction, raising an
            investigation or error correction request with your bank for any
            reason (frNGNulent or otherwise) will result in a dispute fee. The
            current dispute fee is $25 NGN, and is subject to change as
            determined by our payment provider. You agree we may deduct the
            dispute fee from any available funds in your Profitrade accounts.
          </p>
          <p>
            9.5. When you make payments through Profitrade, Profitrade does not
            have control of, or liability for, the delivery, quality, safety,
            legality or any other aspect of any goods or Services that you may
            purchase from, or sell to, a third party. We do not guarantee the
            identity of any user or other party or ensure that a buyer will
            complete a transaction. If there are any problems with the way in
            which a third party has transacted with you, or has provided
            Services to you, you must handle them directly with that third
            party.
          </p>
          <p>
            9.6. It is your responsibility to determine what, if any, taxes
            apply to the payments you make or receive, and it is your
            responsibility to collect, report and remit the correct tax to the
            appropriate tax authority. Profitrade is not responsible for
            determining whether taxes apply to your transaction, or for
            collecting, reporting or remitting any taxes arising from any
            transaction.
          </p>
          <p>
            Goods and Services Tax (VAT) for Nigerian residents may also apply
            to some of our Services. We will provide you with a valid receipt if
            VAT has been collected from you. We will not charge VAT on
            cross-border transactions where you are an eligible Nigerian-based
            business recipient and you have provided your Nigerian Business
            Number (ABN) to us prior to the transaction.
          </p>
          <p>
            9.7. Amount of Fees. You agree to pay the fees for trades completed
            via our Services (“Fees”) as made available via the Fees and Limits,
            which we may change from time to time. Changes to the Fee Schedule
            are effective as of the effective date indicated in the posting of
            the revised Fee Schedule to the Services, and will apply
            prospectively to any trades that take place following the effective
            date of such revised Fee Schedule.
          </p>
          <p>
            9.8. Third-Party Fees. In addition to the Fees, your External
            Account may impose fees in connection with your use of your
            designated External Account via the Services. Any fees imposed by
            your External Account provider will not be reflected on the
            transaction screens containing information regarding applicable
            Fees. You are solely responsible for paying any fees imposed by an
            External Account provider.
          </p>
          <p>
            9.9. Payment of Fees. You authorize us, or our designated payment
            processor, to charge or deduct your Profitrade Account Funds for any
            applicable Fees owed in connection with trades you complete via the
            Services.
          </p>
        </div>
      </div>

      <div class="terms_block">
        <h3 class="title_num">10. Electronic Notices</h3>
        <div class="terms_text_w">
          <p>
            10.1. Consent to Electronic Delivery. You agree and consent to
            receive electronically all communications, agreements, documents,
            receipts, notices and disclosures (collectively, “Communications”)
            that Profitrade provides in connection with your Profitrade Account
            and/or use of the Profitrade Services. You agree that Profitrade may
            provide these Communications to you by posting them via the
            Services, by emailing them to you at the email address you provide.
            You should maintain copies of electronic Communications by printing
            a paper copy or saving an electronic copy. You may also contact us
            through support to request additional electronic copies of
            Communications or, for a fee, paper copies of Communications (as
            described below).
          </p>
          <p>
            10.2. Hardware and Software Requirements. In order to access and
            retain electronic Communications, you will need a computer with an
            Internet connection that has a current web browser with cookies
            enabled and 128-bit encryption. You will also need to have a valid
            email address on file with Profitrade and have sufficient storage
            space to save past Communications or an installed printer to print
            them.
          </p>
          <p>
            10.3. Withdrawal of Consent. You may withdraw your consent to
            receive electronic Communications by sending a withdrawal notice to
            support. If you decline or withdraw consent to receive electronic
            Communications, Profitrade may suspend or terminate your use of the
            Services.
          </p>
          <p>
            10.4. Requesting Paper Copies. If, after you consent to receive
            Communications electronically, you would like a paper copy of a
            Communication we previously sent you, you may request a copy within
            30 days after the date we provided the Communication to you by
            contacting support. In order for us to send paper copies to you, you
            must have a current street address on file with Profitrade. Please
            note that Profitrade operates exclusively online and it is very
            burdensome for us to produce paper copies of Communications.
            Therefore, if you request paper copies, you understand and agree
            that Profitrade may charge you a processing fee, in the amount
            described in the Fees and Limits, for each page of Communication
            requested.
          </p>
          <p>
            10.5. Updating Contact Information. It is your responsibility to
            keep your email address on file with Profitrade up to date so that
            Profitrade can communicate with you electronically. You understand
            and agree that if Profitrade sends you an electronic Communication
            but you do not receive it because your email address on file is
            incorrect, out of date, blocked by your service provider, or you are
            otherwise unable to receive electronic Communications, Profitrade
            will be deemed to have provided the Communication to you. Please
            note that if you use a spam filter that blocks or re-routes emails
            from senders not listed in your email address book, you must add
            Profitrade to your email address book so that you will be able to
            receive the Communications we send to you. You can update your email
            address at any time by logging into your Profitrade Account or by
            sending such information to support. If your email address becomes
            invalid such that electronic Communications sent to you by
            Profitrade are returned, Profitrade may deem your account to be
            inactive, and you may not be able to complete any transaction via
            our Services until we receive a valid, working email address from
            you.
          </p>
        </div>
      </div>

      <div class="terms_block">
        <h3 class="title_num">11. Unclaimed Property</h3>
        <div class="terms_text_w">
          <p>
            If for any reason Profitrade is holding Funds in your Profitrade
            Account on your behalf and Profitrade is unable to return your Funds
            to your designated External Account after a period of inactivity,
            then Profitrade may report and remit such Funds in accordance with
            applicable state unclaimed property laws.
          </p>
        </div>
      </div>

      <div class="terms_block">
        <h3 class="title_num">12. Acceptable Use</h3>
        <div class="terms_text_w pb-5">
          <p>
            12.1. When accessing or using the Services, you agree that you will
            not violate any law, contract, intellectual property or other
            third-party right or commit a tort, and that you are solely
            responsible for your conduct while using our Services. Without
            limiting the generality of the foregoing, you agree that you will
            not:
          </p>
        </div>
        <ul class="terms_list">
          <li class="terms_item">
            Use our Services in any manner that could interfere with, disrupt,
            negatively affect or inhibit other users from fully enjoying our
            Services, or that could damage, disable, overburden or impair the
            functioning of our Services in any manner;
          </li>
          <li class="terms_item">
            Use our Services to pay for, support or otherwise engage in any
            illegal gambling activities; frNGN; money-laundering; or terrorist
            activities; or other illegal activities;
          </li>
          <li class="terms_item">
            Use any robot, spider, crawler, scraper or other automated means or
            interface not provided by us to access our Services or to extract
            data;
          </li>
          <li class="terms_item">
            Use or attempt to use another user’s account without authorization;
          </li>
          <li class="terms_item">
            Attempt to circumvent any content filtering techniques we employ, or
            attempt to access any service or area of our Services that you are
            not authorized to access;
          </li>
          <li class="terms_item">
            Develop any third-party applications that interact with our Services
            without our prior written consent;
          </li>
          <li class="terms_item">
            Provide false, inaccurate, or misleading information;
          </li>
          <li class="terms_item">
            Encourage or induce any third party to engage in any of the
            activities prohibited under this Section.
          </li>
        </ul>
      </div>

      <div class="terms_block">
        <h3 class="title_num">13. Feedback</h3>
        <div class="terms_text_w">
          <p>
            We will own exclusive rights, including all intellectual property
            rights, to any feedback, suggestions, and ideas or other information
            or materials regarding Profitrade or our Services that you provide,
            whether by email, posting through our Services or otherwise
            (“Feedback”). Any Feedback you submit is non-confidential and shall
            become the sole property of Profitrade. We will be entitled to the
            unrestricted use and dissemination of such Feedback for any purpose,
            commercial or otherwise, without acknowledgment or compensation to
            you. You waive any rights you may have to the Feedback (including
            any copyrights). Do not send us Feedback if you expect to be paid or
            want to continue to own or claim rights in them; your idea might be
            great, but we may have already had the same or a similar idea and we
            do not want disputes. We also have the right to disclose your
            identity to any third party who is claiming that any content posted
            by you constitutes a violation of their intellectual property
            rights, or of their right to privacy. We have the right to remove
            any posting you make on our website if, in our opinion, your post
            does not comply with the content standards set out in this section.
          </p>
        </div>
      </div>

      <div class="terms_block">
        <h3 class="title_num">
          14. Copyrights and Other Intellectual Property Rights
        </h3>
        <div class="terms_text_w">
          <p>
            14.1. Unless otherwise indicated by us, all copyright and other
            intellectual property rights in all content and other materials
            contained on our website or provided in connection with the
            Services, including, without limitation, the Profitrade or
            Profitrade logo and all designs, text, graphics, pictures,
            information, data, software, sound files, other files and the
            selection and arrangement thereof (collectively, “Profitrade
            Materials”) are the proprietary property of Profitrade or our
            licensors or suppliers and are protected by international copyright
            laws and other intellectual property rights laws.
          </p>
          <p>
            14.2. We hereby grant you a limited, nonexclusive and
            non-sublicensable license to access and use the Profitrade Materials
            for your personal or internal business use. Such license is subject
            to these Terms and does not permit (a) any resale of the Profitrade
            Materials; (b) the distribution, public performance or public
            display of any Profitrade Materials; (c) modifying or otherwise
            making any derivative uses of the Profitrade Materials, or any
            portion thereof; or (d) any use of the Profitrade Materials other
            than for their intended purposes. The license granted under this
            Section will automatically terminate if we suspend or terminate your
            access to the Services.
          </p>
        </div>
      </div>

      <div class="terms_block">
        <h3 class="title_num">15. Third-Party Content</h3>
        <div class="terms_text_w">
          <p>
            In using our Services, you may view content provided by third
            parties, including links to web pages of such parties, including but
            not limited to Facebook and Twitter links (“Third-Party Content”).
            We do not control, endorse or adopt any Third-Party Content and
            shall have no responsibility for Third-Party Content, including
            without limitation material that may be misleading, incomplete,
            erroneous, offensive, indecent or otherwise objectionable. In
            addition, your business dealings or correspondence with such third
            parties are solely between you and the third parties. We are not
            responsible or liable for any loss or damage of any sort incurred as
            the result of any such dealings, and you understand that your use of
            Third-Party Content, and your interactions with third parties, is at
            your own risk.
          </p>
        </div>
      </div>

      <div class="terms_block">
        <h3 class="title_num">16. Suspension; Termination</h3>
        <div class="terms_text_w">
          <p>
            In the event of any Force Majeure Event (as defined in Section
            22.5), breach of this agreement, or any other event that would make
            provision of the Services commercially unreasonable for Profitrade,
            we may, in our discretion and without liability to you, with or
            without prior notice, suspend your access to all or a portion of our
            Services. We may terminate your access to the Services in our sole
            discretion, immediately and without prior notice, and delete or
            deactivate your Profitrade Account and all related information and
            files in such account without liability to you, including, for
            instance, in the event that you breach any term of these Terms. In
            the event of termination, Profitrade will return any Funds stored in
            your Profitrade Account not otherwise owed to Profitrade, unless
            Profitrade believes you have committed frNGN, negligence or other
            misconduct.
          </p>
        </div>
      </div>

      <div class="terms_block">
        <h3 class="title_num">17. Discontinuance of Services</h3>
        <div class="terms_text_w">
          <p>
            We may, in our sole discretion and without liability to you, with or
            without prior notice and at any time, modify or discontinue,
            temporarily or permanently, any portion of our Services.
          </p>
        </div>
      </div>

      <div class="terms_block">
        <h3 class="title_num">18. Disclaimer of Warranties</h3>
        <div class="terms_text_w">
          <p>
            18.1. Except as expressly provided to the contrary in a writing by
            us, our services are provided on an “As is” and “As available”
            basis. We expressly disclaim, and you waive, all warranties of any
            kind, whether express or implied, including, without limitation,
            implied warranties of merchantability, fitness for a particular
            purpose, title and non-infringement as to our services, including
            the information, content and materials contained therein.
          </p>
          <p>
            18.2. You acknowledge that information you store or transfer through
            our services may become irretrievably lost or corrupted or
            temporarily unavailable due to a variety of causes, including
            software failures, protocol changes by third party providers,
            internet outages, force majeure event or other disasters including
            third party DDOS attacks, scheduled or unscheduled maintenance, or
            other causes either within or outside our control. You are solely
            responsible for backing up and maintaining duplicate copies of any
            information you store or transfer through our services.
          </p>
          <p>
            Some jurisdictions do not allow the disclaimer of implied terms in
            contracts with consumer, so some or all of the disclaimers in this
            section may not apply to you.
          </p>
          <p>
            18.3. Without prejudice to the generality of the foregoing
            paragraph, Profitrade does not warrant that:
          </p>
          <ul class="terms_list">
            <li class="terms_item">
              this Website will be constantly available, or available at all; or
            </li>
            <li class="terms_item">
              the information on this Website is complete, true, accurate or
              non-misleading.
            </li>
          </ul>
          <p>
            Profitrade does not warrant that any part of this Website
            constitutes, or is meant to constitute, advice of any kind. If you
            require advice in relation to any legal or financial matter you
            should consult an appropriate professional.
          </p>
        </div>
      </div>

      <div class="terms_block">
        <h3 class="title_num">19. Limitation of Liability</h3>
        <div class="terms_text_w">
          <p>
            19.1. Except as otherwise required by law, in no event shall
            Profitrade, our directors, members, employees or agents be liable
            for any special, indirect or consequential damages, or any other
            damages of any kind, including but not limited to loss of use, loss
            of profits or loss of data, whether in an action in contract, tort
            (including but not limited to negligence) or otherwise, arising out
            of or in any way connected with the use of or inability to use our
            services or the Profitrade materials, including without limitation
            any damages caused by or resulting from reliance by any user on any
            information obtained from Profitrade, or that result from mistakes,
            omissions, interruptions, deletion of files or email, errors,
            defects, viruses, delays in operation or transmission or any failure
            of performance, whether or not resulting from a force majeure event,
            communications failure, theft, destruction or unauthorized access to
            Profitrade’s records, programs or services.
          </p>
          <p>
            Some jurisdictions do not allow the exclusion of certain warranties
            or the limitation or exclusion of liability for incidental or
            consequential damages. Accordingly, some of the limitations of this
            section may not apply to you.
          </p>
          <p>
            19.2. To the maximum extent permitted by applicable law, in no event
            shall the aggregate liability of Profitrade (including our
            directors, members, employees and agents), whether in contract,
            warranty, tort (including negligence, whether active, passive or
            imputed), product liability, strict liability or other theory,
            arising out of or relating to the use of, or inability to use,
            Profitrade or to these terms exceed the fees paid by you to
            Profitrade during the 12 months immediately preceding the date of
            any claim giving rise to such liability.
          </p>
        </div>
      </div>

      <div class="terms_block">
        <h3 class="title_num">20. Indemnity</h3>
        <div class="terms_text_w">
          <p>
            You agree to defend, indemnify and hold harmless Profitrade (and
            each of our officers, directors, members, employees, agents and
            affiliates) from any claim, demand, action, damage, loss, cost or
            expense, including without limitation reasonable attorneys’ fees,
            arising out or relating to <b>(a)</b> your use of, or conduct in
            connection with, our Services; <b>(b)</b> any Feedback you provide;
            <b>(c)</b> your violation of these Terms; or <b>(d)</b> your
            violation of any rights of any other person or entity. If you are
            obligated to indemnify us, we will have the right, in our sole
            discretion, to control any action or proceeding (at our expense) and
            determine whether we wish to settle it.
          </p>
        </div>
      </div>

      <div class="terms_block">
        <h3 class="title_num">21. Applicable Law; Dispute Resolution</h3>
        <div class="terms_text_w">
          <p>
            Profitrade is registered with AUSTRAC as a Digital Currency Exchange
            and operates under the Australian laws.
          </p>
          <ul class="terms_list">
            <li class="pb-5">21.1 Procedure to make a complaint</li>
            <li class="pb-5">
              1. Contact us via the support ticket system available in your
              account and let us know about your concern.
            </li>
            <li class="pb-5">2. We will attempt to resolve your complaint as soon as possible
              by</li>
            <li>
              <ul class="terms_list_sub">
                <li class="terms_item_sub">
                  discussing it with you to make sure we understand the issue;
                </li>
                <li class="terms_item_sub">
                  do whatever is possible to resolve the issue;
                </li>
                <li class="terms_item_sub">
                  regularly update you on the progress of the review;
                </li>
                <li class="terms_item_sub">
                  provide a final response/decision within maximum 45 days.
                </li>
              </ul>
            </li>
            <li class="pb-5">3. Other Review Options</li>
            <li class="">
              <ul class="terms_list_sub">
                <li class="terms_item_sub">
                  If for any reason we are unable to resolve the complaint
                  within 45 days, or you are not happy with our final response,
                  you may refer your complaint to the Financial Ombudsman
                  Service.
                </li>
                <li class="terms_item_sub">
                  FOS offers a free, independent dispute resolution service for
                  the Nigerian banking, insurance and investment industries. You
                  can contact FOS on 1800 367 287, or by writing to Financial
                  Ombudsman Service, GPO Box 3, Melbourne, VIC 3001, or online
                  at
                  <a class="terms_desc_links" target="_blank" href="https://fos.org.au"
                  >www.fos.org.au</a
                  >
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>

      <div class="terms_block">
        <h3 class="title_num">22. Miscellaneous</h3>
        <div class="terms_text_w">
          <p>22.1. Entire Agreement; Order of Precedence.</p>
          <p>
            These Terms contain the entire agreement, and supersede all prior
            and contemporaneous understandings between the parties regarding the
            Services. These Terms do not alter the terms or conditions of any
            other electronic or written agreement you may have with Profitrade
            for the Services or for any other Profitrade product or service or
            otherwise. In the event of any conflict between these Terms and any
            other agreement you may have with Profitrade, the terms of that
            other agreement will control only if these Terms are specifically
            identified and declared to be overridden by such other agreement.
          </p>
          <p>22.2. Amendment.</p>
          <p>
            We reserve the right to make changes or modifications to these Terms
            from time to time, in our sole discretion. If we make changes to
            these Terms, we will provide you with notice of such changes, such
            as by sending an email, providing notice on the homepage of the Site
            and/or by posting the amended Terms via the applicable Profitrade
            websites and mobile applications and updating the “Last Updated”
            date at the top of these Terms. The amended Terms will be deemed
            effective immediately upon posting for any new users of the
            Services. In all other cases, the amended Terms will become
            effective for preexisting users upon the earlier of either:
            <b>(i)</b> the date users click or press a button to accept such
            changes, or <b>(ii)</b> continued use of our Services 30 days after
            Profitrade provides notice of such changes. Any amended Terms will
            apply prospectively to use of the Services after such changes become
            effective. If you do not agree to any amended Terms, you must
            discontinue using our Services and contact us to terminate your
            account.
          </p>
          <p>22.3.Waiver.</p>
          <p>
            Our failure or delay in exercising any right, power or privilege
            under these Terms shall not operate as a waiver thereof.
          </p>
          <p>22.4. Severability.</p>
          <p>
            The invalidity or unenforceability of any of these Terms shall not
            affect the validity or enforceability of any other of these Terms,
            all of which shall remain in full force and effect.
          </p>
          <p>22.5. Force Majeure Events.</p>
          <p>
            Profitrade shall not be liable for <b>(1)</b> any inaccuracy, error,
            delay in, or omission of (i) any information, or (ii) the
            transmission or delivery of information; <b>(2)</b> any loss or
            damage arising from any event beyond Profitrade’s reasonable
            control, including but not limited to flood, extraordinary weather
            conditions, earthquake, or other act of God, fire, war,
            insurrection, riot, labor dispute, accident, action of government,
            communications, power failure, or equipment or software malfunction
            or any other cause beyond Profitrade’s reasonable control (each, a
            “Force Majeure Event”).
          </p>
          <p>22.6. Assignment.</p>
          <p>
            You may not assign or transfer any of your rights or obligations
            under these Terms without prior written consent from Profitrade,
            including by operation of law or in connection with any change of
            control. Profitrade may assign or transfer any or all of its rights
            under these Terms, in whole or in part, without obtaining your
            consent or approval.
          </p>
          <p>22.7. Headings.</p>
          <p>
            Headings of sections are for convenience only and shall not be used
            to limit or construe such sections.
          </p>
          <p>22.8. Survival.</p>
          <p>
            Sections 2 (Eligibility), Section 3 (Profitrade Account), 8 (Risk
            Disclosure), 9 (Fees), 11 (Unclaimed Property), 13 (Feedback), 14
            (Copyrights), 15 (Third-Party Content), 18 (Disclaimer of
            Warranties), 19 (Limitation of Liability); 20 (Indemnity), 21
            (Applicable Law; Arbitration) and this Section 22 (Miscellaneous)
            shall survive any termination or expiration of these Terms.
          </p>
          <p>
            Profitrade does not accept customers from the following
            jurisdictions: USA, countries on the FATF high risk countries list,
            such as Cuba, North Korea, Syria, Iran, Iraq and others. Risk
            Warning: Trading in bitcoin and other digital assets is risky and
            may potentially result in losses.
          </p>
          <p></p>
        </div>
      </div>

      <div class="terms_profitrade_date">
        <p>Profitrade, 1st August 2020</p>
      </div>
    </section>
    <!-- Terms of Service Content >>>> end -->
  </div>
</template>

<script>
export default {
  name: "TermsOfService",
};
</script>

<style scoped></style>
