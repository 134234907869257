<template>
  <!-- Homepage section <<<< start -->
  <div class="home">
    <!-- Wherebuy section <<<< start -->
    <section class="wherebuy container px-0 px-md-3">
      <div class="wherebuy_in row g-md-5">
        <div
          class="wherebuy_text col-12 col-md-6 d-flex flex-column justify-content-center"
        >
          <h1 class="main_header">Where the locals buy Bitcoin</h1>
          <div class="wherebuy_text_w">
            <p>Easy and secure way to buy and sell bitcoin and crypto tokens</p>
          </div>
          <div class="wherebuy_btn_gr">
            <!--            <button class="wherebuy_login me-3">Log In</button>-->
            <a
              href="https://ozcapital.net/login"
              class="wherebuy_login me-md-3"
              v-show="!ozcapitalRegisterURL"
              >Log In</a
            >
            <!--            <button class="wherebuy_signup">Sign Up</button>-->
            <a :href="ozcapitalRegisterURL" class="wherebuy_signup">Sign Up</a>
          </div>
        </div>

        <div class="wherebuy_i_w col-12 col-md-6 mt-3">
          <img
            src="../assets/images/home-where-bitcoin.png"
            alt="Bitcoin"
            class="wherebuy_i mw-100"
          />
        </div>
      </div>
      <!-- Form <<<< start -->
      <div id="buy_sell" class="currency-exchange-w">
        <!--        HERE WILL BE A FORM-->
        <CurrencyExchange />
      </div>
      <!-- Form >>>> end -->
    </section>
    <!-- Wherebuy section >>>> end -->

    <!-- Local payment section<<<< start -->
    <section class="convenient container">
      <h2 class="title v1-mod mx-auto">Convenient local payment options</h2>

      <div class="convenient_row row row-cols-1 row-cols-md-3">
        <div class="col d-flex justify-content-end">
          <div class="convenient_block">
            <span class="convenient_text">Bank transfers</span>
            <div class="convenient_i_w">
              <img
                src="../assets/home-ico/bank-transfers-2.svg"
                alt="Bank transfers"
                class="convenient_i"
              />
            </div>
          </div>
        </div>
        <div class="col d-flex justify-content-end">
          <div class="convenient_block">
            <span class="convenient_text">Credit card payments</span>
            <div class="convenient_i_w">
              <img
                src="../assets/home-ico/credit_card_payments-2.svg"
                alt="Credit card payments"
                class="convenient_i"
              />
            </div>
          </div>
        </div>
        <div class="col d-flex justify-content-end">
          <div class="convenient_block">
            <span class="convenient_text">Cash deposits</span>
            <div class="convenient_i_w">
              <img
                src="../assets/home-ico/cash_deposits-2.svg"
                alt="Cash deposits"
                class="convenient_i"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Local payment section >>>> end -->

    <!-- Buying bitcoin and crypto currencies section<<<< start -->
    <section class="buycoin container">
      <h2 class="title v2-mod mx-auto">
        Buying bitcoin and crypto currencies is easy as 1,2,3
      </h2>
      <div class="buycoin_row row row-cols-1 row-cols-md-3 g-md-5 gx-5">
        <div class="col">
          <div class="buycoin_block">
            <span class="buycoin_text">Sign up, get verified</span>
            <div class="buycoin_i_w">
              <img
                src="../assets/home-ico/buycoin-01.svg"
                alt=""
                class="buycoin_i"
              />
            </div>
          </div>
        </div>
        <div class="col">
          <div class="buycoin_block">
            <span class="buycoin_text">Add money to your account </span>
            <div class="buycoin_i_w">
              <img
                src="../assets/home-ico/buycoin-02.svg"
                alt=""
                class="buycoin_i"
              />
            </div>
          </div>
        </div>
        <div class="col">
          <div class="buycoin_block">
            <span class="buycoin_text">Buy crypto currencies</span>
            <div class="buycoin_i_w">
              <img
                src="../assets/home-ico/buycoin-03.svg"
                alt=""
                class="buycoin_i"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Buying bitcoin and crypto currencies section >>>> end -->

    <!-- Safety and Security<<<< start -->
    <section class="safety container">
      <div class="safety_in row">
        <div class="safety_i_w col-12 col-md-6">
          <img
            src="../assets/images/home-safetyandsecurity-bitcoin.png"
            alt="Bitcoin"
            class="safety_i mw-100"
          />
        </div>

        <div
          class="safety_text col-12 col-md-6 d-flex flex-column justify-content-center"
        >
          <h2 class="title title_safety mx-auto mx-md-0">
            Safety and Security
          </h2>
          <div class="safety_text_w">
            <p>
              Your personal details are protected by strong SSL encryption All
              crypto funds are stored securely. Practically all crypto assets
              are held in secure “cold” wallets, not accessible online.
            </p>
          </div>
          <div class="safety_btn_gr">
            <a :href="ozcapitalRegisterURL" class="safety_btn">Get Started</a>
          </div>
        </div>
      </div>
    </section>
    <!-- Safety and Security >>>> end -->

    <!-- Cards <<<< start -->
    <section class="home_card container">
      <div
        class="home_card_row row row-cols-1 row-cols-md-3 g-4 g-md-5 justify-content-center"
      >
        <div class="col">
          <div class="home_card_block col">
            <div class="home_card_i_w">
              <img
                src="../assets/home-ico/global-reach.png"
                alt="Bank transfers"
                class="home_card_i"
              />
            </div>
            <div class="home_card_text_w">
              <p class="home_card_text">
                Locally based service with global reach
              </p>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="home_card_block col">
            <div class="home_card_i_w">
              <img
                src="../assets/home-ico/ngn-deposits.png"
                alt="NGN deposits"
                class="home_card_i"
              />
            </div>
            <div class="home_card_text_w">
              <p class="home_card_text">
                No fees for NGN deposits and withdrawal
              </p>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="home_card_block col">
            <div class="home_card_i_w">
              <img
                src="../assets/home-ico/counter-deposit.png"
                alt="Counter deposit options"
                class="home_card_i"
              />
            </div>
            <div class="home_card_text_w">
              <p class="home_card_text">
                Online and over the counter deposit options
              </p>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="home_card_block col">
            <div class="home_card_i_w">
              <img
                src="../assets/home-ico/encrypted-wallets.png"
                alt="Encrypted wallets"
                class="home_card_i"
              />
            </div>
            <div class="home_card_text_w">
              <p class="home_card_text">
                Most crypto funds are held offline in encrypted wallets
              </p>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="home_card_block col">
            <div class="home_card_i_w">
              <img
                src="../assets/home-ico/high-daily-deposit.png"
                alt="High daily deposit and withdrawal limits"
                class="home_card_i"
              />
            </div>
            <div class="home_card_text_w">
              <p class="home_card_text">
                High daily deposit and withdrawal limits
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Cards >>>> end -->

    <!-- Form Support <<<< start -->
    <FormSupport />
    <!-- Form Support >>>> end -->
  </div>
  <!-- Homepage >>>> end -->
</template>

<script>
import CurrencyExchange from '../components/CurrencyExchange';
import FormSupport from '../components/FormSupport';

export default {
  name: 'Home',
  components: {
    FormSupport,
    CurrencyExchange,
  },
  data() {
    return {
      ozcapitalRegisterURL: process.env.VUE_APP_OZ_REDIRECT_URL,
    };
  },
  // components: {CurrencyExchange},
};
</script>
<style></style>
