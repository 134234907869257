<template>
  <div class="privacy">
    <!-- Privacy Policy info <<<< start -->
    <section class="privacy_in_w container">
      <div class="privacy_in row">
        <div class="privacy_text col-12 col-md-6">
          <h1 class="main_header">Privacy Policy</h1>
          <div class="privacy_text_w">
            <p>
              Profitrade is taking necessary steps to comply with the relevant
              government regulations in order to prevent fraud, money-laundering
              and terrorist-financing. We take privacy very seriously.
            </p>
            <p>
              We are bound by the International Privacy Laws. This Privacy Policy
              sets out the manner in which we comply with our obligations under
              these laws.
            </p>
          </div>
        </div>

        <div class="privacy_i_w col-12 col-md-6 text-center text-md-end">
          <img
            src="../assets/images/privacy-policy.png"
            alt="Bitcoin"
            class="privacy_i mw-100"
          />
        </div>
      </div>
    </section>
    <!-- Privacy Policy info >>>> end -->

    <section class="privacy_desc container">
      <div class="privacy_block">
        <h2 class="title">Collection, use and disclosure</h2>
        <div class="privacy_text_w">
          <p>
            We collect personal information from customers for identity
            verification purposes under anti-money laundering and fraud
            prevention laws.
          </p>
          <p>
            We only use and disclose personal information collected in this
            manner insofar as necessary in order to integrate with identity
            verification and related services, or as otherwise required by law.
          </p>
          <p>
            There are also a number of limited circumstances in which we may
            collect personal information directly from you. This is limited to
            circumstances where you make direct contact with us by telephone or
            in writing, or via our website at independentreserve.com. We will
            only use personal information collected in this manner for the
            purpose of communicating with you or otherwise in accordance with
            the purpose for which you provided your personal information.
          </p>
        </div>
      </div>
      <div class="privacy_block">
        <h2 class="title">The personal information we collect</h2>
        <div class="privacy_text_w">
          <p>
            The type of personal information which we might collect from
            customers includes your name, current and previous addresses, date
            of birth, telephone number, driver licence number, passport number
            and any other information which may be relevant for the purpose of
            verifying your identity. We require our third party providers and
            corporate customers to comply with all relevant privacy and data
            protection laws (including, where necessary, obtaining the
            appropriate express or implied consent from you at the time your
            personal information is collected) in order for such information to
            be lawfully used for identity verification and related purposes.
          </p>
          <p>
            We do not collect, use or disclose any sensitive information about
            any person. This includes a person’s racial or ethnic origin,
            political opinion, political association, religious belief or
            affiliation, philosophical belief, professional or trade union or
            association, sexual preference, criminal record or health
            information.
          </p>
          <p>
            We do not use or adopt as our own identifier any individual
            identifier assigned by any other organisation.
          </p>
        </div>
      </div>
      <div class="privacy_block">
        <h2 class="title">Security</h2>
        <div class="privacy_text_w">
          <p>
            We take all reasonable steps (including the employment of
            appropriate security systems and procedures) to ensure all data held
            by us is safe and secure. Such security measures include physical
            protection and electronic encryption to prevent any unauthorised
            access.
          </p>
        </div>
      </div>
      <div class="privacy_block">
        <h2 class="title">Quality</h2>
        <div class="privacy_text_w">
          <p>
            We take all reasonable steps to ensure that any personal information
            that we collect and use is accurate, complete and up-to-date.
          </p>
        </div>
      </div>
      <div class="privacy_block">
        <h2 class="title">Access and correction</h2>
        <div class="privacy_text_w">
          <p>
            You have a right to access your personal information, subject to
            some exceptions allowed by law. If you wish to access your personal
            information, you should contact us via the support tickets system.
            We reserve the right to charge a fee for searching for and providing
            access to your information.
          </p>
        </div>
      </div>
      <div class="privacy_block">
        <h2 class="title">Destruction</h2>
        <div class="privacy_text_w">
          <p>
            Where we determine, acting reasonably, that personal information
            held in respect of an individual is no longer needed for our
            functions or the services we provide, we will permanently destroy or
            de-identify that information.
          </p>
        </div>
      </div>
      <div class="privacy_block">
        <h2 class="title">Amendments to the policy</h2>
        <div class="privacy_text_w">
          <p>
            We may from time to time vary this Privacy Policy. Please check our
            website regularly for an up-to-date version of our Privacy Policy.
          </p>
        </div>
      </div>
      <div class="privacy_block">
        <h2 class="title">Anti Money Laundering (AML) Policy</h2>
        <div class="privacy_text_w">
          <p>
            Profitrade follows a strict Anti-Money Laundering Policy in
            accordance with relevant international laws. This policy is designed
            to ensure that no money transacted via the exchange is used as part
            of any money laundering operation or terrorism financing.
          </p>
          <p>
            Various AML measures are used to prevent and report any attempt to
            use our services to launder money, to finance any illegal
            activities.
          </p>
        </div>
      </div>
      <div class="privacy_block">
        <h2 class="title">
          Some of the preventive measures we use are as follows
        </h2>
        <div class="privacy_text_w">
          <p class="pb-4">The following is a list of some of these measures:</p>
          <ul class="terms_list">
            <li class="terms_item">
              All clients are subject to verification procedure.
            </li>
            <li class="terms_item">
              Deposits and Withdrawals in fiat currency (i.e. NGN, USD, etc) can
              only be made using a bank account held in the same name as the
              account with us
            </li>
            <li class="terms_item">
              We do not accept third party transfers and any such transfer will
              be returned to the sender.
            </li>
            <li class="terms_item">
              We are obligated to report any suspicious activity to the
              regulating authorities
            </li>
          </ul>
          <p>
            Profitrade exchange reserves the right to refuse service to
            customers from certain jurisdictions.
          </p>
        </div>
      </div>
      <div class="privacy_profitrade">
        <p>Profitrade 2021</p>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "TermsOfService",
  components: {},
};
</script>

<style scoped></style>
