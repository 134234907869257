<template>
  <header class="header container d-flex flex-wrap">
    <div class="logo_w d-flex justify-content-between">
      <router-link :to="{ name: 'home' }">
        <img src="../assets/logo.svg" alt="Logo" class="logo" />
      </router-link>

      <div class="burger_w">
        <button class="burger" @click.prevent="toggle = !toggle">
          <span class="burger_span"></span>
        </button>
      </div>
    </div>

    <NavMenuDesktop />
    <NavMenuMobile
      :class="{ nav_w_active: toggle }"
      v-show="toggle"
      @hide="toggle = e"
    />

    <!--    {{ toggle }}-->

    <div class="auth_btn_group">
      <a
        v-show="!ozcapitalRegisterURL"
        href="https://ozcapital.net/login"
        class="nav_btn nav_login"
        >Log In</a
      >
      <a :href="ozcapitalRegisterURL" class="nav_btn nav_signup">Sign Up</a>
    </div>
  </header>
</template>

<script>
import NavMenuDesktop from './NavMenuDesktop';
import NavMenuMobile from './NavMenuMobile';
import { onMounted, ref } from 'vue';
export default {
  name: 'Header',
  components: {
    NavMenuMobile,
    NavMenuDesktop,
  },
  setup() {
    const toggle = ref(false);
    const ozcapitalRegisterURL = ref(process.env.VUE_APP_OZ_REDIRECT_URL);

    onMounted(() => {});

    return {
      toggle,
      ozcapitalRegisterURL,
    };
  },
};
</script>

<style scoped></style>
