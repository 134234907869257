<template>
  <div
    ref="views"
    class="currency-exchange-view row d-flex justify-content-between"
    v-bind:class="[justForm ? 'home' : '']"
  >
    <form class="col-12 col-lg-7" @submit.prevent="onSubmit">
      <div class="exchange-crypto">
        <h2 class="title exchange-crypto">Exchange Crypto</h2>
        <p class="text-gradient">Amount to Exchange</p>
        <div>
          <p
            :style="{
              color: isOutMinRange ? 'red !important' : 'black !important',
            }"
            class="text-muted minmax"
          >
            Min amount you can exchange is <span>100 000.00</span> NGN
          </p>
          <p
            :style="{
              color: isOutMaxRange ? 'red !important' : 'black !important',
            }"
            class="text-muted"
          >
            Max amount you can exchange is
            <span>5 000 000.00</span> NGN
          </p>
        </div>
        <div
          v-if="!this.$mobileVersion"
          class="jc-sb row d-flex flex-wrap mb-2 mb-sm-4"
        >
          <div class="col-12 col-md-6">
            <div class="form-group-select-input">
              <input
                v-model.trim.number="currentCreditAmount"
                :model-value="currentCreditAmount"
                class="ex_input"
                name="credit"
                placeholder="Enter Amount"
                @input="onInput"
              />
              <div class="ex_select" @click="toggleCurrecySelect">
                <div
                  :class="[{ open: toggleSelectCredit }]"
                  class="ex_select__title"
                  v-text="currentCreditValue"
                ></div>
                <transition name="fade">
                  <ul
                    :class="[{ 'ex--open': toggleSelectCredit }]"
                    class="ex_select__list"
                  >
                    <li
                      v-for="(item, key) in currencyList"
                      :key="key"
                      :value="item"
                      @click.stop="selectCredit"
                    >
                      {{ item }}
                    </li>
                  </ul>
                </transition>

                <input
                  v-model="creditCurrency"
                  name="credit_currency"
                  type="hidden"
                />
              </div>
            </div>
          </div>
          <!-- col-12 col-md-6-->
          <div class="col-12 col-md-6">
            <div class="form-group-select-input">
              <input
                v-model.trim.number="currentDebitAmount"
                class="ex_input"
                name="debit"
                placeholder="You'll get"
                @input="onInput"
              />

              <div class="ex_select" @click="toggleDebitSelect">
                <div
                  :class="[{ open: toggleSelectDebit }]"
                  class="ex_select__title"
                  v-text="currentDebitValue"
                ></div>
                <transition name="fade">
                  <ul
                    :class="[{ 'ex--open': toggleSelectDebit }]"
                    class="ex_select__list"
                  >
                    <li
                      v-for="(item, key) in currentDebitList"
                      :key="key"
                      :value="item"
                      @click.stop="selectDebit"
                    >
                      {{ item }}
                    </li>
                  </ul>
                </transition>
                <input
                  v-model="debitCurrency"
                  name="credit_currency"
                  type="hidden"
                />
              </div>
            </div>
          </div>
        </div>
        <div v-else class="jc-sb">
          <div class="form-group input-select">
            <input
              v-model="creditValue"
              name="credit"
              placeholder="0.0000"
              step="1e-8"
              type="number"
              @input="creditValueMethod"
            />
            <select v-model="creditCurrency" name="credit_currency">
              <option
                v-for="(item, key) in currencyListFiats"
                :key="key"
                :value="key"
              >
                {{ key }}
              </option>
            </select>
          </div>
          <div class="form-group input-select">
            <input
              v-model="debitValue"
              name="debit"
              placeholder="0.0000"
              step="1e-8"
              type="number"
              @input="debitValueMethod"
            />
            <select v-model="debitCurrency" name="debit_currency">
              <option
                v-for="(item, key) in currencyListCrypts"
                :key="key"
                :value="key"
              >
                {{ key }}
              </option>
            </select>
          </div>
        </div>
        <!--   :disabled="!accessDenied"     -->
        <button
          v-if="!justForm"
          class="btn btn-solid exchange-now-btn my-2 my-sm-4"
          type="submit"
        >
          <span>Exchange Now</span>
        </button>
        <!-- exchange front form btn -->
        <button
          v-else-if="user"
          :disabled="creditValue === null"
          class="btn btn-solid"
          @click.prevent="toDashboard"
        >
          <span>Exchange Now!</span>
        </button>
        <!-- exchange without login form btn -->
        <button v-else class="btn btn-solid" @click.prevent="toLogin">
          <span>Exchange Now!</span>
        </button>
      </div>
      <input name="operation" type="hidden" value="exchange" />
      <!-- exchange dashboard form btn -->
    </form>

    <div class="exchange-rates col-12 col-lg-5">
      <div class="exchange-rates-block mx-3">
        <h3 class="digital-title">DIGITAL CURRENCY</h3>
        <h3 class="digital-title">RATE</h3>
      </div>

      <div
        v-for="(item, key) in digitalCurrency"
        :key="key"
        class="exchange-rates-block mx-3"
      >
        <div class="exchange-rates-content">
          <div class="exchange-rates-img-w me-4">
            <img
              :src="require(`../assets/exchange-currency-ico/${item.icon}.png`)"
              alt=""
            />
          </div>
          <div class="exchange-rates-crypto">
            {{ item.crypto }}
          </div>
        </div>

        <div class="exchange-rates-content">
          <div class="exchange-rates-costs">{{ item.costs }}&nbsp;</div>
          <div class="exchange-rates-currency">
            {{ item.currency }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*import PopupConfirm from "../../base/PopupConfirm";
import Balances from "../base/Balances";
import VAlert from "../base/AlertMessage";
import MinMax from "../base/MinMax";
import CurrencyList from "../base/CurrencyList";*/
// import { mapGetters, mapActions, mapState } from "vuex";
// import moment from "moment";
/*import ExchangeRateTimer from "../base/ExchangeRateTimer";
import BuyMaxButton from "../base/BuyMaxButton";*/
import axios from "axios";
import { computed, onMounted, reactive, toRefs, watch } from "vue";

const apiCurrencies = "https://ozcapital.net/api/currencies";
const apiFees = "https://ozcapital.net/api/exchange-fees";

const ozLoginUrl = process.env.VUE_APP_OZ_REDIRECT_URL;
const access_key = process.env.VUE_APP_RATE_KEY;
const apiCoin = process.env.VUE_APP_RATE_URL;

export default {
  name: "CurrencyExchange",

  setup() {
    const data = reactive({
      currencies: [],
      currentCreditValue: "NGN",
      currentDebitValue: "BTC",
      currentCreditAmount: null,
      currentDebitAmount: null,
      toggleSelectCredit: false,
      toggleSelectDebit: false,
      currencyCryptoList: [],
      currencyFiatList: [],
      isSubbmiting: false,
      minValue: 100_000,
      maxValue: 5_000_000,
      isOutMinRange: false,
      isOutMaxRange: false,
      fees: [],
      rates: [],
    });

    onMounted(async () => {
      await getFees();
      getExchangeCurrencies().then(() => {
        getRates().then(() => {
          setCurrenciesRate("NGN");
        });
      });
    });

    const isCrypto = (value) => data.currencyCryptoList.includes(value);

    // const currentCrypto = computed(() =>
    //   isCrypto(data.currentDebitValue)
    //     ? data.currentDebitValue
    //     : data.currentCreditValue
    // );

    // const currentCryptoRate = computed(
    //   () => data.currencies.find((c) => c.crypto === currentCrypto.value).costs
    // );

    const currencyList = computed(() => [
      ...data.currencyFiatList,
      ...data.currencyCryptoList,
    ]);

    const currentDebitList = computed(() =>
      isCrypto(data.currentCreditValue)
        ? data.currencyFiatList
        : data.currencyCryptoList
    );

    const digitalCurrency = computed(() => {
      const initCurrencies = [
        {
          icon: "BTC",
          crypto: "BTC",
          costs: 0,
          currency: "NGN",
        },
        {
          icon: "ETH",
          crypto: "ETH",
          costs: 0,
          currency: "NGN",
        },
        {
          icon: "XRP",
          crypto: "XRP",
          costs: 0,
          currency: "NGN",
        },
        {
          icon: "DOGE",
          crypto: "DOGE",
          costs: 0,
          currency: "NGN",
        },
      ];

      const cryptoToRender = initCurrencies.map(({ crypto }) => crypto);
      const filteredCurrencies = data.currencies
        ?.filter((currency) => cryptoToRender.includes(currency.crypto))
        .map((currency) => ({
          ...currency,
          costs: getAmountWithFee(currency.costs).toFixed(2),
        }));

      return data.currencies.length ? filteredCurrencies : initCurrencies;
    });

    const getCryptoAmount = (value) => value?.toFixed(8);

    const getFiatAmount = (value) => value?.toFixed(2);

    const getAmountWithFee = (value) => {
      const fee = data.fees?.find(
        (fee) =>
          fee.currency_from === data.currentCreditValue &&
          fee.currency_to === data.currentDebitValue
      );
      const amount = fee?.amount || 1;

      return value * ((100 - Number(amount)) / 100);
    };

    // const getAmount = (currency, value) => {
    //   console.log("args ->", value, currency, currentCryptoRate.value);
    //   const crypto = (1 / currentCryptoRate.value) * value;
    //   const fiat = currentCryptoRate.value * value;
    //   return isCrypto(currency) ? getAmountWithFee(crypto).toFixed(8) : getAmountWithFee(fiat).toFixed(2);
    // };

    const getFees = async () => {
      return axios
        .get(apiFees, {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        })
        .then((res) => {
          data.fees = res?.data?.data;
        })
        .catch((error) => {
          console.log("Error is: ", error);
        });
    };

    const getExchangeCurrencies = async () => {
      return axios
        .get(apiCurrencies)
        .then((res) => {
          const { fiat = {}, crypto = {} } = res.data;
          data.currencyFiatList = Object.keys(fiat).filter((f) => f !== "NGN");
          data.currencyCryptoList = Object.keys(crypto);
          data.currencyFiatList?.unshift("NGN");
        })
        .catch((error) => {
          console.log("Error is: ", error);
        });
    };

    const setCurrenciesRate = async (currency) => {
      const currencies = data.currencyCryptoList.map((crypto) => {
        const costs = getFiatAmount(data.rates[crypto]);
        return {
          icon: crypto,
          crypto,
          costs: costs || 0,
          currency,
        };
      });

      data.currencies = await Promise.all(currencies);
    };

    const getExchangeRate = async (args) => {
      const result = await axios
        .get(apiCoin + "/convert", { params: { access_key, ...args } })
        .then((res) => res?.data?.result)
        .catch((error) => {
          console.log("Error is: ", error);
        });
      return result;
    };

    const getRates = async () => {
      const params = {
        access_key,
        target: data.currentCreditValue,
        symbols: data.currencyCryptoList.join(),
      };
      return axios
        .get(apiCoin + "/live", { params })
        .then((res) => {
          data.rates = res?.data?.rates;
        })
        .catch((error) => {
          console.log("Error is: ", error);
        });
    };

    const onSubmit = () => {
      window.location = ozLoginUrl;
    };

    const toggleCurrencySelect = () => {
      data.toggleSelectCredit = !data.toggleSelectCredit;
    };

    const toggleDebitSelect = () => {
      data.toggleSelectDebit = !data.toggleSelectDebit;
    };

    watch(
      () => data.currentCreditValue,
      (credit, prevCredit) => {
        if (isCrypto(prevCredit) && isCrypto(credit)) {
          return;
        }
        if (isCrypto(credit)) {
          data.currentDebitValue = "NGN";
          setCreditAmount();
        } else {
          data.currentDebitValue = "BTC";
          setCreditAmount();
        }
      },
      { deep: true }
    );

    const setCreditAmount = async () => {
      if (data.currentCreditValue === data.currentDebitValue || !data.currentCreditAmount) {
        return;
      }

      const params = {
        from: data.currentCreditValue,
        to: data.currentDebitValue,
        amount: data.currentCreditAmount,
      };
      getExchangeRate(params).then((result) => {
        const resultWithFee = getAmountWithFee(result);
        data.currentDebitAmount = !isCrypto(data.currentCreditValue)
          ? getCryptoAmount(resultWithFee)
          : getFiatAmount(resultWithFee);
      });
    };

    const setDebitAmount = async () => {
      if (data.currentCreditValue === data.currentDebitValue || !data.currentDebitAmount) {
        return;
      }

      const params = {
        from: data.currentDebitValue,
        to: data.currentCreditValue,
        amount: data.currentDebitAmount,
      };

      getExchangeRate(params).then((result) => {
        const resultWithFee = getAmountWithFee(result);
        data.currentCreditAmount = isCrypto(data.currentCreditValue)
          ? getCryptoAmount(resultWithFee)
          : getFiatAmount(resultWithFee);
      });
    };

    const clearMinMaxRangeAlert = () => {
      data.isOutMinRange = false;
      data.isOutMaxRange = false;
    };
    const selectCredit = (event) => {
      data.currentCreditValue = event.target.textContent;
      data.toggleSelectCredit = false;
      setCreditAmount();
      clearMinMaxRangeAlert();
    };

    const selectDebit = (event) => {
      data.currentDebitValue = event.target.textContent;
      data.toggleSelectDebit = false;
      setDebitAmount();
      clearMinMaxRangeAlert();
    };

    const setCredit = (amount) => {
      // const debit = data.currentDebitValue;

      if (amount < data.minValue && data.currentCreditValue === "NGN") {
        data.isOutMinRange = true;
        return;
      } else if (amount > data.maxValue && data.currentCreditValue === "NGN") {
        data.isOutMaxRange = true;
        return;
      } else {
        clearMinMaxRangeAlert();
      }

      // data.currentDebitAmount = getAmount(debit, amount);
      setCreditAmount();
    };

    // const setDebit = (amount) => {
    //   const credit = data.currentCreditValue;
    //
    //   // data.currentCreditAmount = getAmount(credit, amount);
    //   setDebitAmount()
    // };

    const onInput = ({ target }) => {
      const amount = Number(target.value);
      const name = target.name;

      if (Number.isNaN(amount)) {
        return;
      }

      if (!amount || amount <= 0) {
        data.currentCreditAmount = null;
        data.currentDebitAmount = null;
        clearMinMaxRangeAlert();
        return;
      }

      if (name === "debit") {
        // setDebit(amount);

        setDebitAmount()
      }
      if (name === "credit") {
        setCredit(amount);
      }
    };

    return {
      ...toRefs(data),
      currencyList,
      onSubmit,
      selectCredit,
      selectDebit,
      toggleCurrecySelect: toggleCurrencySelect,
      toggleDebitSelect,
      getExchangeCurrecies: getExchangeCurrencies,
      onInput,
      digitalCurrency,
      currentDebitList,
    };
  },
  // props: ["userId", "user", "justForm"],
  // components: {
  //   /*PopupConfirm,
  //   Balances,
  //   VAlert,
  //   MinMax,
  //   CurrencyList,
  //   ExchangeRateTimer,
  //   BuyMaxButton,*/
  // },

  /*data() {
    return {
      currencyListCredit: [
        { currency: "NGN" },
        { currency: "USD" },
        { currency: "UAH" },
      ],
      currencyListDebit: [],
      digitalCurrency: [
        {
          icon: "btc",
          crypto: "BTC",
          costs: "9,562",
          currency: "NGN",
        },
        {
          icon: "eth",
          crypto: "ETH",
          costs: "8,562",
          currency: "NGN",
        },
        {
          icon: "xrp",
          crypto: "XRP",
          costs: "7,562",
          currency: "NGN",
        },
        {
          icon: "eos",
          crypto: "EOS",
          costs: "6,562",
          currency: "NGN",
        },
      ],

      /!*isCurrent: false,
      creditCurrency: "BTC",
      debitCurrency: "AUD",
      debitValue: null,
      creditValue: null,
      isChange: true,
      alertText: null,
      btcAudRate: "",
      ethAudRate: "",
      getInfoSuccess: false,
      isConfirm: false,
      textEvent: "",
      loadingRate: false,
      minAmountForWithdraw: "100.00",
      maxAmountForWithdraw: "1000000.00",
      typeMoneyExchange: "crypto",
      toggleSelectCredit: false,
      toggleSelectDebit: false,
      oldCurr: "",
      exchangeFee: 0,
      currencyKey: 10,
      paymentMethod: {
        bank_transfer: "Bank transfer",
        post_billpay: "Post Billpay",
      },
      lastTimeExchangeRate: null,
      exchngeSuccess: true,*!/
    };
  },*/
  /* methods: {
    async onSubmit() {
      try {
        const {data} = await axios.get("https://ozcapital.net/api/currencies");
        const currencyList = data.currency;
        console.log(currencyList)
      } catch (error) {
        console.log("Error is: ", error);
      }
    },
  },*/
  /*computed: {
    checkFiat() {

    }
  },*/
  /*mounted: function () {
    this.onSubmit();
  },*/
  // computed: {
  //   ...mapGetters([
  //     "getBalance",
  //     "loading",
  //     "getUserInfo",
  //     "getExchangeFees",
  //     "getCurrencyExchange",
  //     "getRateToken",
  //     "getRate",
  //     "getInfoRate",
  //   ]),
  //   ...mapState({
  //     dedied: (state) => state.exchangeRateModule.exchangeDenied,
  //     setFetchExchangeError: (state) =>
  //       state.exchangeRateModule.setFetchExchangeError,
  //   }),
  //   fiatList() {
  //     return this.getCurrencyExchange.fiat;
  //   },
  //   cryptoList() {
  //     return this.getCurrencyExchange.crypto;
  //   },
  //   currencyListCredit() {
  //     return {
  //       ...this.fiatList,
  //       ...this.cryptoList,
  //     };
  //   },
  //   currencyListDebit() {
  //     return this.typeMoneyExchange != "crypto"
  //       ? this.cryptoList
  //       : this.fiatList;
  //   },
  //   // creditValue: {
  //   //   get: function () {
  //   //     console.log("creditValue :>> ");
  //   //     return this;
  //   //   },
  //   //   set: async function (newVal) {
  //   //     console.log("newVal c :>> ", newVal);
  //   //     if (this.isChange) {
  //   //       this.getCurrentRate("credit");
  //   //       this.isChange = false;
  //   //     } else {
  //   //       this.isChange = true;
  //   //     }
  //   //   },
  //   // },
  //   // debitValue: {
  //   //   get: function () {
  //   //     console.log("debitValue :>> ");
  //   //     return this;
  //   //   },
  //   //   set: async function (newVal) {
  //   //     console.log("newVal d :>> ", newVal);
  //   //     if (this.isChange) {
  //   //       this.getCurrentRate("debit");
  //   //       this.isChange = false;
  //   //     } else {
  //   //       this.isChange = true;
  //   //     }
  //   //   },
  //   // },
  //   // currCreditList() {
  //   //   let list = [];
  //   //   Object.keys(this.currencyListCredit).map((el) => {
  //   //     list.push(el);
  //   //     // list.push({ value: el, label: this.currencyListCredit[el] });
  //   //   });
  //   //   return list;
  //   // },
  //   accessDenied() {
  //     return (
  //       this.creditValue >= this.minAmountForWithdraw &&
  //       this.creditValue <= this.maxAmountForWithdraw &&
  //       this.loadingRate &&
  //       this.exchngeSuccess &&
  //       this.dedied
  //     );
  //   },
  //   exchngeSuccessStatus() {
  //     return {
  //       min: this.creditValue < this.minAmountForWithdraw,
  //       max: this.creditValue > this.maxAmountForWithdraw,
  //     };
  //   },
  // },
  // methods: {
  //   ...mapActions([
  //     "FETCH_INFO_NOT_LOGIN",
  //     "FETCH_DATA",
  //     "GET_RATE",
  //     "GET_EXCHANGE_FEE",
  //     "FETCH_USER_DATA",
  //     "GET_EXCHANGE_RATE",
  //   ]),
  //   selectDebit(event, key = this.debitCurrency) {
  //     event.stopPropagation();
  //     this.toggleSelectDebit = !this.toggleSelectDebit;
  //     this.debitCurrency = key;
  //   },
  //   toDashboard() {
  //     let data = JSON.stringify({
  //       amount: this.creditValue,
  //       from: this.creditCurrency,
  //       to: this.debitCurrency,
  //     });
  //     localStorage.setItem("pageExchange", data);
  //     window.location = "/user/dashboard/currency-exchange";
  //   },
  //   toLogin() {
  //     let data = JSON.stringify({
  //       amount: this.creditValue,
  //       from: this.creditCurrency,
  //       to: this.debitCurrency,
  //     });
  //     localStorage.setItem("pageExchange", data);
  //     window.location = "/login";
  //   },
  //   async setMaxBuyBalance(val) {
  //     this.alertText = null;
  //     // this.creditCurrency = val;
  //     // await this.getMinMax();
  //     if (this.getBalance[val] === undefined || this.getBalance[val] === 0) {
  //       this.alertText = {
  //         type: "error",
  //         text: `Your balance: 0 ${val}`,
  //       };
  //       return;
  //     } else if (this.getBalance[val] < this.minAmountForWithdraw) {
  //       this.alertText = {
  //         type: "error",
  //         text: `Min amount for exchange ${this.minAmountForWithdraw} ${val}`,
  //       };
  //       return;
  //     } else if (this.getBalance[val] > this.maxAmountForWithdraw) {
  //       this.alertText = {
  //         type: "error",
  //         text: `Max amount for exchange ${this.maxAmountForWithdraw} ${val}`,
  //       };
  //       this.creditValue = this.maxAmountForWithdraw;
  //       await this.creditValueMethod();
  //       return;
  //     } else {
  //       this.creditValue = this.getBalance[val];
  //       await this.creditValueMethod();
  //     }
  //
  //     // else {
  //     //   this.creditValue = this.getBalance[val]
  //     //   console.log('this.getBalance[this.creditValue] :>> ', this.getBalance);
  //     // }
  //   },
  //   async getCurrentRate(type) {
  //     let exFrom, exTo;
  //     this.loadingRate = false;
  //
  //     // if (this.creditValue === null) {
  //     //   this.getMinMax();
  //     //   return;
  //     // }
  //     // this.oldCurr = this.creditCurrency;
  //     switch (type) {
  //       case "credit":
  //         exFrom = this.creditCurrency;
  //         exTo = this.debitCurrency;
  //         break;
  //       case "debit":
  //         exTo = this.creditCurrency;
  //         exFrom = this.debitCurrency;
  //         break;
  //       default:
  //         break;
  //     }
  //     try {
  //       await this.GET_EXCHANGE_RATE({
  //         credit_currency: exFrom,
  //         debit_currency: exTo,
  //       });
  //       this.exchangeFee = this.getExchangeFees(
  //         this.creditCurrency,
  //         this.debitCurrency
  //       );
  //       if (this.typeMoneyExchange === "fiat") {
  //         this.minAmountForWithdraw = 100;
  //         this.maxAmountForWithdraw = 100000;
  //       } else {
  //         this.minAmountForWithdraw = +(
  //           100 /
  //           this.getRate /
  //           ((100 - Number(this.exchangeFee)) / 100)
  //         ).toFixed(8);
  //         // this.minAmountForWithdraw = Number(100 / this.getRate);
  //         this.maxAmountForWithdraw = +(
  //           100000 /
  //           this.getRate /
  //           ((100 - Number(this.exchangeFee)) / 100)
  //         ).toFixed(8);
  //       }
  //       let result = 0;
  //       switch (type) {
  //         case "credit":
  //           result =
  //             this.creditValue *
  //             ((100 - Number(this.exchangeFee)) / 100) *
  //             this.getRate;
  //           if (this.typeMoneyExchange === "crypto") {
  //             this.debitValue = result.toFixed(2);
  //           } else {
  //             this.debitValue = result.toFixed(8);
  //           }
  //           break;
  //         case "debit":
  //           result =
  //             this.debitValue *
  //             ((100 - Number(this.exchangeFee)) / 100) *
  //             this.getRate;
  //           if (this.typeMoneyExchange === "crypto") {
  //             this.creditValue = result.toFixed(8);
  //           } else {
  //             this.creditValue = result.toFixed(2);
  //           }
  //           break;
  //         default:
  //           break;
  //       }
  //       this.exchngeSuccess = true;
  //     } catch (e) {
  //       this.alertText = {
  //         type: "error",
  //         text: `Wrong exchange rate`,
  //       };
  //       this.minAmountForWithdraw = 0;
  //       this.maxAmountForWithdraw = 0;
  //       this.exchngeSuccess = false;
  //     }
  //     this.loadingRate = true;
  //   },
  //   async getMinMax() {
  //     this.loadingRate = false;
  //     // if (this.typeMoneyExchange === "fiat") {
  //     //   this.minAmountForWithdraw = 100;
  //     //   this.maxAmountForWithdraw = 100000;
  //     //   this.loadingRate = true;
  //     // } else {
  //     try {
  //       await this.GET_EXCHANGE_RATE({
  //         credit_currency: this.creditCurrency,
  //         debit_currency: this.debitCurrency,
  //       });
  //       this.exchangeFee = this.getExchangeFees(
  //         this.creditCurrency,
  //         this.debitCurrency
  //       );
  //       if (this.typeMoneyExchange === "fiat") {
  //         this.minAmountForWithdraw = 100;
  //         this.maxAmountForWithdraw = 100000;
  //         this.loadingRate = true;
  //       } else {
  //         this.minAmountForWithdraw = (
  //           100 /
  //           this.getRate /
  //           ((100 - Number(this.exchangeFee)) / 100)
  //         ).toFixed(8);
  //         // this.minAmountForWithdraw = Number(100 / this.getRate);
  //         this.maxAmountForWithdraw = (
  //           100000 /
  //           this.getRate /
  //           ((100 - Number(this.exchangeFee)) / 100)
  //         ).toFixed(8);
  //       }
  //       // this.maxAmountForWithdraw = Number(100000 / this.getRate).toFixed(8);
  //       this.alertText = null;
  //       this.exchngeSuccess = true;
  //     } catch (e) {
  //       this.minAmountForWithdraw = 0;
  //       this.maxAmountForWithdraw = 0;
  //       this.alertText = {
  //         type: "error",
  //         text: `Error issuing exchange rate`,
  //       };
  //       this.exchngeSuccess = false;
  //     }
  //     // }
  //     this.loadingRate = true;
  //   },
  //   confirmEvent(value) {
  //     if (value === "yes") {
  //       this.submit();
  //     }
  //     this.isConfirm = false;
  //   },
  //   confirmExchange() {
  //     if (this.getBalance[this.creditCurrency] === undefined) {
  //       this.alertText = {
  //         type: "error",
  //         text: `Your balance: 0 ${this.creditCurrency}`,
  //       };
  //       return;
  //     } else if (this.creditValue < this.minAmountForWithdraw) {
  //       this.alertText = {
  //         type: "error",
  //         text: `Min amount for exchange ${this.minAmountForWithdraw} ${this.creditCurrency}`,
  //       };
  //       return;
  //     }
  //     this.textEvent = `Exchange <strong>${this.creditValue} ${this.creditCurrency}</strong> to <strong>${this.debitValue} ${this.debitCurrency}</strong>`;
  //     this.isConfirm = true;
  //   },
  //   submit() {
  //     if (this.creditValue === null) {
  //       this.alertText = {
  //         type: "error",
  //         text: `Enter amount`,
  //       };
  //       return;
  //     }
  //     let fd = new FormData();
  //     fd.append("debit", this.debitValue);
  //     fd.append("credit", this.creditValue);
  //     fd.append("hash", this.getRateToken);
  //     fd.append("operation", "exchange");
  //     fd.append("rate", this.getRate);
  //     fd.append("debit_currency", this.debitCurrency);
  //     fd.append("credit_currency", this.creditCurrency);
  //     axios
  //       .post("/user/dashboard/" + this.getUserInfo.id, fd)
  //       .then(async (res) => {
  //         if (res.data.success) {
  //           this.alertText = {
  //             type: "success",
  //             text: `Success exchange: ${this.creditValue} ${this.creditCurrency} to ${this.debitValue} ${this.debitCurrency}`,
  //           };
  //           this.debitValue = null;
  //           this.creditValue = null;
  //           this.creditCurrency = "BTC";
  //           this.debitCurrency = "AUD";
  //           // this.minAmountForWithdraw = "100.00";
  //           this.getCurrentRate("credit");
  //           await this.FETCH_DATA();
  //         } else {
  //           this.alertText = {
  //             type: "error",
  //             text: res.data.message,
  //           };
  //         }
  //       });
  //   },
  //   creditValueMethod: _.debounce(async function () {
  //     if (this.justForm) {
  //       await this.getCurrentRate("credit");
  //       return;
  //     }
  //     if (
  //       this.creditValue > Number(this.getBalance[this.creditCurrency]) &&
  //       this.creditValue > this.maxAmountForWithdraw
  //     ) {
  //       this.alertText = {
  //         type: "error",
  //         text: `Max amount to exchange: ${
  //           this.creditValue > this.maxAmountForWithdraw
  //             ? this.maxAmountForWithdraw
  //             : this.getBalance[this.creditCurrency]
  //         } ${this.creditCurrency}`,
  //       };
  //     }
  //     if (String(this.creditValue).indexOf(".") === 0 && this.isChange) {
  //       this.creditValue = 0 + this.creditValue;
  //     }
  //     await this.getCurrentRate("credit");
  //   }, 500),
  //   debitValueMethod: _.debounce(async function () {
  //     if (this.justForm) {
  //       await this.getCurrentRate("credit");
  //       return;
  //     }
  //
  //     if (String(this.debitValue).indexOf(".") === 0 && this.isChange) {
  //       this.debitValue = 0 + this.debitValue;
  //     }
  //     await this.getCurrentRate("debit");
  //   }, 500),
  // },
  // watch: {
  //   creditCurrency: function () {
  //     this.loadingRate = false;
  //     let tmp = Object.keys(this.fiatList);
  //     let key = this.creditCurrency;
  //     if (
  //       Object.keys(this.currencyListDebit).find((el) => key === el) !=
  //       undefined
  //     ) {
  //       if (tmp.find((el) => key === el) === undefined) {
  //         this.typeMoneyExchange = "crypto";
  //         // this.currencyListDebit = this.fiatList;
  //
  //         this.debitCurrency =
  //           this.debitCurrency != "AUD" ? Object.keys(this.fiatList)[0] : "AUD";
  //       } else {
  //         this.typeMoneyExchange = "fiat";
  //         // this.currencyListDebit = this.cryptoList;
  //         this.minAmountForWithdraw = 100;
  //         this.maxAmountForWithdraw = 100000;
  //         this.debitCurrency = Object.keys(this.cryptoList)[0];
  //       }
  //     }
  //
  //     if (this.creditValue != null) {
  //       this.creditValueMethod();
  //       this.isChange = false;
  //     } else {
  //       this.getMinMax();
  //       this.isChange = true;
  //     }
  //     this.loadingRate = true;
  //   },
  //   debitCurrency: function () {
  //     this.loadingRate = false;
  //     if (this.debitValue != null) {
  //       this.creditValueMethod();
  //       this.isChange = false;
  //     } else {
  //       this.getMinMax();
  //       this.isChange = true;
  //     }
  //     this.loadingRate = true;
  //   },
  // },
  //
  // async mounted() {
  //   this.justForm && (await this.FETCH_INFO_NOT_LOGIN());
  //   await this.GET_EXCHANGE_FEE();
  //   if (localStorage.getItem("pageExchange")) {
  //     let data = JSON.parse(localStorage.getItem("pageExchange"));
  //     this.creditCurrency = data.from;
  //     this.debitCurrency = data.to;
  //     this.creditValue = data.amount;
  //     localStorage.removeItem("pageExchange");
  //     this.getCurrentRate("credit");
  //   }
  //   this.getMinMax();
  //   window.addEventListener("click", (e) => {
  //     if (e.target.closest(".ex_select") === null) {
  //       this.toggleSelectCredit = false;
  //       this.toggleSelectDebit = false;
  //     }
  //   });
  // },
};
</script>

<style lang="scss" scoped></style>
