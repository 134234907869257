/* eslint-disable prettier/prettier */
import { createStore } from 'vuex'


const index = createStore({
	state () {
		return {
      count: 0,
			country: "",
		}
	},
	mutations: {
		increment (state) {
			state.count++
		},
		addCountry(state, payload) {
			state.country = payload;
		}
	}
})


export default index
