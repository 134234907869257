<template>
  <section id="support" class="support">
    <div id="modal"></div>
    <Form
      id="form_support"
      @submit="onSubmit"
      class="row gy-3"
      :validation-schema="schema"
      v-slot="{ errors, isSubmitting }"
    >
      <h2 class="title text-center">Support</h2>
      <Field
        v-model="name"
        type="text"
        name="name"
        id="name"
        as="input"
        class="input-root form-control"
        :class="{ 'is-invalid': !!errors.name }"
        placeholder="Enter Your Name"
      />
      <ErrorMessage
        name="name"
        v-slot="{ message }"
        class="invalid-feedback"
        as="div"
      >
        {{ message }}
      </ErrorMessage>
      <Field
        v-model="email"
        type="text"
        name="email"
        id="email"
        as="input"
        class="input-root form-control"
        :class="{ 'is-invalid': !!errors.email }"
        placeholder="Enter Your Email"
      />
      <ErrorMessage
        name="email"
        v-slot="{ message }"
        class="invalid-feedback"
        as="div"
      >
        {{ message }}
      </ErrorMessage>
      <Field
        v-model="subject"
        type="text"
        name="subject"
        id="subject"
        as="input"
        class="input-root form-control"
        :class="{ 'is-invalid': !!errors.subject }"
        placeholder="Enter Subject"
      />
      <ErrorMessage
        name="subject"
        v-slot="{ message }"
        class="invalid-feedback"
        as="div"
      >
        {{ message }}
      </ErrorMessage>
      <Field
        v-model="message"
        type="text"
        name="message"
        id="message"
        as="textarea"
        class="input-root form-control textarea_support"
        :class="{ 'is-invalid': !!errors.message }"
        placeholder="Enter Your Message"
      />
      <ErrorMessage
        name="message"
        v-slot="{ message }"
        class="invalid-feedback"
        as="div"
      >
        {{ message }}
      </ErrorMessage>

      <div class="col-12 gy-4 text-center">
        <button class="btn_support" type="submit" :disabled="isSubmitting">
          Submit
        </button>
      </div>
    </Form>
  </section>
  <!-- Modal <<<< start  <button @click.prevent="modalOpen = true">
    Open full screen modal! (With teleport!)
  </button> -->

  <teleport to="main">
    <div v-if="modalOpen" class="modal">
      <section>
        <img
          class="modal-close"
          src="../assets/modal-ico/close.svg"
          alt=""
          @click="modalOpen = false"
        />
        <img class="modal-image" src="../assets/modal-ico/send.svg" alt="" />
        <p>{{ messageModal }}</p>
        <button @click="modalOpen = false">Ok</button>
      </section>
    </div>
  </teleport>
  <!-- Modal >>>> end -->
</template>

<script>
import axios from 'axios';
import { reactive, toRefs } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as Yup from 'yup';

export default {
  name: 'FormSupport',

  components: {
    Form,
    Field,
    ErrorMessage,
  },

  setup() {
    const data = reactive({
      name: '',
      email: '',
      subject: '',
      message: '',
      modalOpen: false,
      isSubmitting: false,
      messageModal: '',
    });

    const onSubmit = async (form, { resetForm }) => {
      //  const api = 'https://ozcapital.net/api/send-mail';
      //  const api = 'https://ozcapital.net/api/support-email';
      const api = 'https://ozcapital.net/api/profitrade-support-email';
      const formRequest = {...form, account_number: ""}
      await axios
        .post(api, formRequest)
        .then(response => {
          data.messageModal = response.data.message;
          data.modalOpen = true;
          resetForm();
        })
        .catch(error => {
          data.messageModal = error.response.data.message;
          data.modalOpen = true;
          console.log('Error is: ', error);
        });
    };

    const schema = Yup.object().shape({
      name: Yup.string().required('Name is a required field'),
      email: Yup.string()
        .email('Please correct email adress')
        .required('Email is a required field'),
      subject: Yup.string().required('Subject is a required field').min(6).max(15),
      message: Yup.string().required('Message is a required field').min(10)
    });

    return { onSubmit, schema, ...toRefs(data) };
  },
};
</script>

<style scoped></style>
