<template>
  <main class="main">
    <Header />
    <router-view></router-view>
    <Footer />
  </main>
  <!--  {{ country }}-->
</template>
<script>
/* eslint-disable prettier/prettier */
// import store from "@/store/index";
// import {onMounted, computed} from "vue";
import Header from "@/components/Header";
import Footer from "@/components/Footer";
// import {useRoute} from "vue-router"

export default {
  components: {Footer, Header},
  setup() {
    // const route = useRoute();
    // const saveCountry = () => store.commit("addCountry", route.query.country);
    // const country = computed(() => store.state.country)

    // onMounted(() => {
      // console.log(route.query);
      // saveCountry();
    // })

    return {
      // country,
      // saveCountry,
    }
  }
}
</script>



<style lang="scss">

</style>