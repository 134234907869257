<template>
  <div class="nav_w">
    <nav class="main_nav_mobile">
      <div class="logo_block">
        <div class="logo_w logo_w_mobile_active">
          <router-link :to="{ name: 'home' }">
            <img alt="" class="logo" src="../assets/logo.png" />
          </router-link>

          <div class="burger_w open">
            <button class="burger_open" @click.prevent="$emit('hide', $event)">
              <span class="burger_span_open"></span>
            </button>
          </div>
        </div>
      </div>
      <ul class="nav_list active">
        <li class="nav_item">
          <a class="nav_link" href="/#buy_sell" @click="$emit('hide', $event)"
            >Buy/Sell</a
          >
        </li>
        <li class="nav_item">
          <a class="nav_link" href="https://ozcapital.net/basics"
            >Crypto Basics</a
          >
        </li>
        <li class="nav_item">
          <a class="nav_link" href="/#support" @click="$emit('hide', $event)"
            >Support</a
          >
        </li>
      </ul>

      <div class="auth_btn_group mobile">
        <a
          v-show="!ozcapitalRegisterURL"
          class="nav_btn nav_login"
          href="https://ozcapital.net/login"
          >Log In</a
        >
        <a :href="ozcapitalRegisterURL" class="nav_btn nav_signup">Sign Up</a>
      </div>
    </nav>
  </div>
</template>

<script>
import { ref } from "vue";

export default {
  name: "NavMenuMobile",
  setup() {
    const ozcapitalRegisterURL = ref(process.env.VUE_APP_OZ_REDIRECT_URL);

    return {
      ozcapitalRegisterURL,
    };
  },
  emits: ["hide"],
};
</script>

<style lang="scss" scoped></style>
