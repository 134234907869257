<template>
  <div class="about container">
    <!-- About Us <<<< start -->
    <section class="info">
      <div class="info_in row d-md-flex justify-content-md-between g-md-5">
        <div
          class="info_text col-12 col-md-6 d-flex flex-column justify-content-center"
        >
          <h1 class="main_header">About Us</h1>
          <div class="info_text_w">
            <p>
              We are a local company based in Lagos area, with plenty of
              experience in crypto sphere. We buy and sell bitcoin and other
              crypto currencies and offer great prices and a fast friendly
              service
            </p>

            <div class="info_payments_text_w mt-5">
              <p class="info_payments_text">We accept local payments</p>
            </div>
          </div>
        </div>
        <div class="info_i_w col-12 col-md-6 text-center text-md-end">
          <img
            src="../assets/images/about-coin.png"
            alt="Bitcoin"
            class="info_i mw-100"
          />
        </div>
      </div>
    </section>
    <!-- About Us >>>> end -->

    <!-- Crypto currencies <<<< start -->
    <div class="currencies container">
      <h2 class="title v3-mod text-start text-md-center">
        You can buy or sell the following crypto currencies through us
      </h2>

      <div
        class="currencies_row row row-cols-1 row-cols-md-4 g-5 justify-content-center"
      >
        <div class="col">
          <div class="currencies_block">
            <div class="currencies_i_w">
              <img
                src="../assets/images-coins/bitcoin-2.png"
                alt="Bank transfers"
                class="currencies_i"
              />
            </div>
            <div class="currencies_text_w">
              <p class="currencies_text">Bitcoin (BTC)</p>
            </div>
          </div>
        </div>

        <div class="col">
          <div class="currencies_block">
            <div class="currencies_i_w">
              <img
                src="../assets/images-coins/ethereum.png"
                alt="NGN deposits"
                class="currencies_i"
              />
            </div>
            <div class="currencies_text_w">
              <p class="currencies_text">Ethereum (ETH)</p>
            </div>
          </div>
        </div>

        <div class="col">
          <div class="currencies_block">
            <div class="currencies_i_w">
              <img
                src="../assets/images-coins/litecoin.png"
                alt="Counter deposit options"
                class="currencies_i"
              />
            </div>
            <div class="currencies_text_w">
              <p class="currencies_text">Litecoin (LTC)</p>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="currencies_block">
            <div class="currencies_i_w">
              <img
                src="../assets/images-coins/tether.png"
                alt="Encrypted wallets"
                class="currencies_i"
              />
            </div>
            <div class="currencies_text_w">
              <p class="currencies_text">Tether (USDT)</p>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="currencies_block">
            <div class="currencies_i_w">
              <img
                src="../assets/images-coins/bitcoin-cash.png"
                alt="High daily deposit and withdrawal limits"
                class="currencies_i"
              />
            </div>
            <div class="currencies_text_w">
              <p class="currencies_text">Bitcoin Cash (BCH)</p>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="currencies_block">
            <div class="currencies_i_w">
              <img
                src="../assets/images-coins/chainlink.png"
                alt="High daily deposit and withdrawal limits"
                class="currencies_i"
              />
            </div>
            <div class="currencies_text_w">
              <p class="currencies_text">Chainlink (LINK)</p>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="currencies_block">
            <div class="currencies_i_w">
              <img
                src="../assets/images-coins/ripple.png"
                alt="High daily deposit and withdrawal limits"
                class="currencies_i"
              />
            </div>
            <div class="currencies_text_w">
              <p class="currencies_text">Ripple (XRP)</p>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="currencies_block">
            <div class="currencies_i_w">
              <img
                src="../assets/images-coins/dogecoin.png"
                alt="High daily deposit and withdrawal limits"
                class="currencies_i"
              />
            </div>
            <div class="currencies_text_w">
              <p class="currencies_text">Dogecoin (DOGE)</p>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="currencies_block">
            <div class="currencies_i_w">
              <img
                src="../assets/images-coins/cardano.png"
                alt="High daily deposit and withdrawal limits"
                class="currencies_i"
              />
            </div>
            <div class="currencies_text_w">
              <p class="currencies_text">Cardano (ADA)</p>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="currencies_block">
            <div class="currencies_i_w">
              <img
                src="../assets/images-coins/tezos.png"
                alt="High daily deposit and withdrawal limits"
                class="currencies_i"
              />
            </div>
            <div class="currencies_text_w">
              <p class="currencies_text">Tezos (XTZ)</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--    <div class="currencies container">
      <h2 class="title v3-mod">
        You can buy or sell the following crypto currencies through us
      </h2>

      <div
        class="row row-cols-1 row-cols-md-4 g-5 justify-content-center"
        v-for="(item, key) in aboutCryptoCurrencyItems"
        :key="key"
      >
        <div class="col">
          <div class="currencies_block">
            <div class="currencies_i_w">
              <img
                :src="require(`../assets/images-coins//${item.icon}.png`)"
                alt="Bank transfers"
                class="currencies_i"
              />
            </div>
            <div class="currencies_text_w">
              <p class="currencies_text">{{ item.coin }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>-->
    <!-- Crypto currencies >>>> end -->

    <!-- Register online and start buying <<<< start -->
    <section class="registry-ad">
      <div class="registry-ad_in row d-md-flex justify-content-md-between">
        <div
          class="registry-ad_text col-12 col-md-6 d-flex flex-column justify-content-center"
        >
          <h2 class="title registry-ad--mod">
            Simply register online and start buying or selling your crypto coins
          </h2>
          <div class="registry-ad_btn_gr">
            <a :href="ozcapitalRegisterURL" class="registry-ad_login"
              >Get Started</a
            >
          </div>
        </div>

        <div
          class="registry-ad_i_w col-12 col-md-6 text-md-end text-center text-md-start"
        >
          <img
            src="../assets/images/about-buying-selling.png"
            alt="Bitcoin"
            class="registry-ad_i mw-100"
          />
        </div>
      </div>
    </section>
    <!-- Register online and start buying >>>> end -->
  </div>
</template>

<script>
import { ref } from 'vue';
export default {
  name: 'About',
  components: {},
  setup() {
    const ozcapitalRegisterURL = ref(process.env.VUE_APP_OZ_REDIRECT_URL);

    return {
      ozcapitalRegisterURL,
    };
  },

  /*setup() {
    const aboutCryptoCurrencyItems = [
      {
        coin: "Bitcoin (BTC)",
        icon: "bitcoin-2",
      },
      {
        coin: "Ethereum (ETH)",
        icon: "ethereum",
      },
      {
        coin: "Ethereum (ETH)",
        icon: "ethereum",
      },
      {
        coin: "Ethereum (ETH)",
        icon: "ethereum",
      },
      {
        coin: "Ethereum (ETH)",
        icon: "ethereum",
      },
      {
        coin: "Ethereum (ETH)",
        icon: "ethereum",
      },
    ];

    return {
      aboutCryptoCurrencyItems,
    };
  },*/
};
</script>
