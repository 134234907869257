import { createRouter, createWebHistory } from "vue-router";
// import Support from "@/components/Support";
import Home from "@/views/Home";
import TermsOfService from "@/components/TermsOfService";
import AmlAndPrivacyPolicy from "@/components/AmlAndPrivacyPolicy";
import About from "@/components/About";

// import VueRouter from "vue-router";

/*const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/about",
    name: "About",
    component: About,
  },
  /!*{
    path: "/home#support",
    name: "Support",
    component: Home,
  },*!/
  {
    path: "/terms-of-service",
    name: "TermsOfService",
    component: TermsOfService,
  },
  {
    path: "/aml-and-privacy-policy",
    name: "AmlAndPrivacyPolicy",
    component: AmlAndPrivacyPolicy,
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/!* webpackChunkName: "about" *!/ "../components/About.vue"),
  },
];*/
const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
    meta: {
      title: "Home",
    },
  },
  {
    path: "/about",
    name: "about",
    component: About,
    meta: {
      title: "About",
    },
  },
  {
    path: "/terms-of-service",
    name: "termsOfService",
    component: TermsOfService,
    meta: {
      title: "TermsOfService",
    },
  },
  {
    path: "/aml-and-privacy-policy",
    name: "aml-and-privacy-policy",
    component: AmlAndPrivacyPolicy,
    meta: {
      title: "AmlAndPrivacyPolicy",
    },
  },
];
const router = createRouter({
  history: createWebHistory(),
  linkExactActiveClass: "active",
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return window.scrollTo({ top: 0, y: 0, behavior: "smooth" });
    }
  },
});
// const router = new VueRouter({
//   mode: "history",
//   linkExactActiveClass: "active",
//
// });

// router.beforeEach((to, from, next) => {
// // console.log(to.meta.title);
// document.title = to.meta.title || "Profitrade";
// next();
// });

export default router;
