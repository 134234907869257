<template>
  <footer class="main_footer">
    <div class="nav_list_w">
      <ul class="nav_list container justify-content-center">
        <li class="nav_item">
          <router-link :to="{ name: 'about' }" class="nav_link"
            >About us</router-link
          >
        </li>
        <li class="nav_item">
          <a href="/#buy_sell" class="nav_link">Buy/Sell</a>
          <!--          <router-link to="/buy-sell" class="nav_link">Buy/Sell</router-link>-->
        </li>
        <!--        <li class="nav_item">
          <a href="https://ozcapital.net/basics" class="nav_link"
          >Crypto Basics</a
          >
&lt;!&ndash;          <router-link to="/crypto-basics" class="nav_link"
          >Crypto Basics</router-link
          >&ndash;&gt;
        </li>-->
        <li class="nav_item">
          <a href="/#support" class="nav_link">Support</a>
          <!--          <router-link to="/home#support" class="nav_link">Support</router-link>-->
        </li>
        <li class="nav_item">
          <!--          <router-link :to="{ name: 'termsOfService' }" class="nav_link"-->
          <router-link :to="{ name: 'termsOfService' }" class="nav_link"
            >Terms of Service</router-link
          >
        </li>
        <li class="nav_item">
          <router-link :to="{ name: 'aml-and-privacy-policy' }" class="nav_link"
            >AML and Privacy Policy</router-link
          >
        </li>
      </ul>
    </div>
    <div class="copy">
      <p>Copyright 2021 Profitrade.net</p>
    </div>
  </footer>
</template>

<script>
export default {
  name: "footer",
};
</script>

<style scoped></style>
