<template>
  <nav class="main_nav_desktop">
    <ul class="nav_list">
      <li class="nav_item">
        <a href="/#buy_sell" class="nav_link">Buy/Sell</a>
      </li>
      <li class="nav_item">
        <a href="https://ozcapital.net/basics" class="nav_link"
        >Crypto Basics</a
        >
      </li>
      <li class="nav_item">
        <a href="/#support" class="nav_link">Support</a>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: "NavMenuDesktop"
}
</script>

<style scoped>

</style>